import { WppTypography } from '@wppopen/components-library-react'
import { NavigateFunction } from 'react-router-dom'

import EmptyState from 'components/EmptyState'
import { IEmptyState } from 'components/EmptyState/types'
import { ProjectsFilterGroup } from 'components/ProjectsFilterGroup'
import { noop } from 'utils/common'

export default function EmptyProjects({
  title,
  description,
  action,
  agencyPermission,
  navigate,
}: IEmptyState & { agencyPermission: boolean; navigate: NavigateFunction }) {
  return (
    <div className="px-4">
      <div className="flex justify-start mb-4">
        <WppTypography type="2xl-heading">My Projects</WppTypography>
      </div>
      <div className="flex justify-center mb-4">
        <ProjectsFilterGroup
          navigate={navigate}
          agencyPermission={agencyPermission}
          disabled
          handleApplyFilters={noop}
          handleResetFilters={noop}
          handleCancelFilters={noop}
          handleSearchChange={noop}
          controlValue="none"
          setFilterControlValue={noop}
          numOfSelectedFilters={0}
          resetFiltersVisible={false}
          handleSortFilter={noop}
          sortingState={{}}
          agencyDropdown={<></>}
          filters={null}
        />
      </div>
      <EmptyState title={title} description={description} action={action} />
    </div>
  )
}
