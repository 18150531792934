import { TabsChangeEventDetail } from '@wppopen/components-library'
import { WppBreadcrumb, WppIconChevron, WppTab, WppTabs, WppTypography } from '@wppopen/components-library-react'
import { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { PaginationParams } from 'api/common/types'
import { getBEErrorMessage } from 'api/index'
import { useGetAgenciesByPermissions } from 'api/queries/agencies/useGetAgencies'
import { useUseCases } from 'api/queries/use-cases/useUseCases'
import { useUseCasesMeta } from 'api/queries/use-cases/useUseCasesMeta'
import { taskQueuer } from 'components/TasksNotifications/TaskQueuer'
import { useSettingsPermissions } from 'hooks/useSettingsPermissions'
import { AgencyDashboardView } from 'pages/agency-dashboard/AgencyDashboardView'

import { UseCasesDashboardView } from './useCases/useCasesDashboardView/UseCasesDashboardView'
import { AgencyFetchDataParams } from '@/types/agencies/agencies'
import { FetchUseCasesFiltersParams, UseCasesMeta } from '@/types/use-cases/useCase'

export enum SettingsTabs {
  AGENCIES = 'agencies',
  USE_CASES = 'use-cases',
}

interface TabSearchParams {
  [SettingsTabs.AGENCIES]: URLSearchParams | null
  [SettingsTabs.USE_CASES]: URLSearchParams | null
}

function removeQueryParams<T extends Record<string, any>>(searchParams: T, keys: string[] = ['view', 'mode']): T {
  keys.forEach(key => delete searchParams[key])

  return searchParams
}
export const Settings = () => {
  const { data: useCasesMeta = {} as UseCasesMeta } = useUseCasesMeta({
    staleTime: 60 * 10 * 1000, // 10 minutes
  })
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const viewQuery = searchParams.get('view')
  const currentTab = viewQuery === SettingsTabs.USE_CASES ? SettingsTabs.USE_CASES : SettingsTabs.AGENCIES
  const [tabSearchParams, setTabSearchParams] = useState<TabSearchParams>({
    [SettingsTabs.AGENCIES]: new URLSearchParams(`view=${SettingsTabs.AGENCIES}&mode=grid&page=1`),
    [SettingsTabs.USE_CASES]: new URLSearchParams(`view=${SettingsTabs.USE_CASES}&mode=grid&page=1`),
  })

  const agencyParams: PaginationParams<AgencyFetchDataParams> | undefined = tabSearchParams[SettingsTabs.AGENCIES]
    ? removeQueryParams(Object.fromEntries(tabSearchParams[SettingsTabs.AGENCIES]?.entries()))
    : undefined

  const useCaseParams: PaginationParams<FetchUseCasesFiltersParams> | undefined = tabSearchParams[
    SettingsTabs.USE_CASES
  ]
    ? removeQueryParams(Object.fromEntries(tabSearchParams[SettingsTabs.USE_CASES]?.entries()))
    : undefined

  useEffect(() => {
    setTabSearchParams(prev => ({ ...prev, [currentTab]: searchParams }))
  }, [searchParams, currentTab])

  const {
    data: { data: dataAgencies, paginator: agenciesPaginator } = {
      data: [],
      paginator: { page: 1, totalItems: 0, totalPages: 0, itemsPerPage: 0 },
    },
    isLoading: loadingAgencies,
    isError: isErrorAgencies,
    error: errorAgencies,
  } = useGetAgenciesByPermissions({
    params: agencyParams,
  })
  const {
    data: { data: dataUseCases, paginator: useCasesPaginator } = {
      data: [],
      paginator: { page: 1, totalItems: 0, totalPages: 0, itemsPerPage: 0 },
    },
    isLoading: loadingUseCases,
    isError: isErrorUseCases,
    error: errorUseCases,
  } = useUseCases({
    params: useCaseParams,
  })

  const { totalItems: useCasesTotal } = useCasesPaginator
  const { totalItems: agenciesTotal } = agenciesPaginator

  const { isAgenciesAdmin } = useSettingsPermissions()

  useEffect(() => {
    if (errorAgencies) {
      const message = getBEErrorMessage(errorAgencies) ?? 'Failed to fetch agencies'
      if (errorMessage !== message) {
        setErrorMessage(message)
      }
    }
  }, [errorAgencies, errorMessage])

  useEffect(() => {
    if (errorUseCases) {
      const message = getBEErrorMessage(errorUseCases) ?? 'Failed to fetch use cases'
      if (errorMessage !== message) {
        setErrorMessage(message)
      }
    }
  }, [errorUseCases, errorMessage])

  useEffect(() => {
    if (errorMessage) {
      taskQueuer.showToast({ message: errorMessage, type: 'error' })
    }
  }, [errorMessage])

  const handleTabChange = (event: CustomEvent<TabsChangeEventDetail>) => {
    const value = event.detail.value
    if (value === SettingsTabs.AGENCIES) {
      if (tabSearchParams[SettingsTabs.AGENCIES]) {
        setSearchParams(tabSearchParams[SettingsTabs.AGENCIES])
      } else {
        setSearchParams({ view: SettingsTabs.AGENCIES, mode: 'grid' })
      }
    } else if (value === SettingsTabs.USE_CASES) {
      if (tabSearchParams[SettingsTabs.USE_CASES]) {
        setSearchParams(tabSearchParams[SettingsTabs.USE_CASES])
      } else {
        setSearchParams({ view: SettingsTabs.USE_CASES, mode: 'grid' })
      }
    }
  }
  return (
    <div className="pb-16">
      <div className="flex mb-4">
        <WppIconChevron direction="left" />
        <WppBreadcrumb
          onWppChange={event => navigate(event.detail.path)}
          items={[{ label: 'Back to Project Dashboard', path: '/' }]}
        />
      </div>

      <div className="flex justify-start mb-4">
        <WppTypography type="xl-heading">Settings</WppTypography>
      </div>

      <WppTabs value={currentTab} onWppChange={handleTabChange}>
        {/* add small padding either side to account size of tab space when count is added */}
        <WppTab className={agenciesTotal === 0 ? 'px-1' : ''} value={SettingsTabs.AGENCIES}>
          Agencies ({agenciesTotal})
        </WppTab>
        {/* add small padding either side to account size of tab space when count is added */}
        <WppTab className={useCasesTotal === 0 ? 'px-1' : ''} value={SettingsTabs.USE_CASES}>
          Use Cases ({useCasesTotal})
        </WppTab>
      </WppTabs>
      {
        {
          [SettingsTabs.AGENCIES]: (
            <AgencyDashboardView
              paginator={agenciesPaginator}
              navigate={navigate}
              agencies={dataAgencies}
              isFetching={loadingAgencies && !isErrorAgencies}
              isAgenciesAdmin={isAgenciesAdmin}
            />
          ),
          [SettingsTabs.USE_CASES]: (
            <UseCasesDashboardView
              paginator={useCasesPaginator}
              navigate={navigate}
              useCases={dataUseCases}
              isFetching={loadingUseCases && !isErrorUseCases}
              meta={useCasesMeta}
            />
          ),
        }[currentTab]
      }
    </div>
  )
}
