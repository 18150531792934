import {
  WppActionButton,
  WppButton,
  WppIconAdd,
  WppIconDone,
  WppIconEdit,
  WppInput,
  WppListItem,
  WppModal,
  WppTypography,
} from '@wppopen/components-library-react'
import { AnalyticsActionType } from '@wppopen/core'
import { useOs } from '@wppopen/react'
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { useAddRfiQuestion } from 'api/mutations/rfis/useAddRfiQuestion'
import { useSetQuestionPosition } from 'api/mutations/rfis/useSetQuestionPosition'
import { useRfiQuestionId } from 'api/queries/rfis/useRfiQuestionId'
import { useRfiQuestions } from 'api/queries/rfis/useRfiQuestions'
import { useTasksStatuses } from 'api/queries/task-status/useTasksStatus'
import { useFetchRfiMembers } from 'api/queries/users/useFetchRfiMembers'
import { queryClient } from 'app/Root'
import ChatCmp from 'components/chat/ChatCmp'
import { ChatCmpLoading } from 'components/chat/ChatCmpLoading'
import { hasRequiredPermission, updateQuestionsCache } from 'components/chat/utils'
import { MultipleContainers } from 'components/draggable-list/DraggableList'
import { ProgressApiRes } from 'components/LoaderProgressWithDescription'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { RfiQuestionContext } from 'contexts/RfiQuestion'
import { TaskContext } from 'contexts/Task'
import { RfiActionType, RfiQuestionAction } from 'contexts/types/rfiQuestion'
import { useToast } from 'hooks/useToast'
import { RfiQuestion } from 'types/rfis/rfi'

import styles from './AnswerQuestionsPage.module.scss'
import { groupAndConvertQuestions } from './utils'
import { ANALYTICS_EVENTS, trackAnalytics } from '../../utils/analytics'

interface ConvertedQuestion {
  id: string
  name: string
  questions: RfiQuestion[]
  parentId?: string | null
}

interface ModalState {
  open: boolean
  content: RfiActionType
}

export default function AnswerQuestionsPage() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [tasks, setTasks] = useState<ProgressApiRes[] | []>([])
  const {
    osContext: {
      userDetails: { email: currentUserEmail },
    },
  } = useOs()
  const { showToast } = useToast()
  const params = useParams()
  const { data: rfiMembers } = useFetchRfiMembers({
    params: {
      rfi_id: params.projectId!,
    },
  })
  const projectMember = rfiMembers?.filter(member => member.memberDetail.email === currentUserEmail)?.[0]
  const toastIsBlocked = useRef(false)
  const [taskResultIds, setTaskResultIds] = useState<string[]>([])
  const { data: rfiQuestion } = useRfiQuestionId({
    params: { question_id: taskResultIds.length > 0 ? taskResultIds[0] : '' },
    enabled: taskResultIds.length > 0,
  })
  const taskIds = tasks?.reduce((acc: string[], task) => {
    if (!!task.id && !task.completed) {
      acc.push(task.id)
    }
    return acc
  }, [])
  const { data: taskStatuses } = useTasksStatuses({
    params: {
      taskIds,
    },
    enabled: tasks?.length > 0,
    refetchInterval: 500,
  })

  const hasIncompleteTasks = taskStatuses.some(task => !task.completed)

  const {
    data: questions = [],
    isFetching,
    isLoading,
  } = useRfiQuestions({
    params: { rfiId: params.projectId || undefined },
  })
  const [convertedQuestions, setConvertedQuestions] = useState([] as ConvertedQuestion[])
  const [inDragMode, setInDragMode] = useState(false)
  const [newQuestionText, setNewQuestionText] = useState('')
  const [optimisticQuestion, setOptimisticQuestion] = useState<false | RfiQuestion>(false)
  const [selectedRfiQuestionId, setSelectedRfiQuestionId] = useState<string | null>(null)
  const [rfiQuestionAction, setRfiQuestionAction] = useState<RfiQuestionAction | null>(null)
  const [newQuestionId, setNewQuestionId] = useState<string | null>(null)
  const [modalState, setModalState] = useState<ModalState>({
    open: false,
    content: null,
  })
  const { mutateAsync: addRfiQuestion, isPending } = useAddRfiQuestion({
    onError: error => {
      showToast({
        message: error.message,
        type: 'error',
      })

      toggleModal()
      setNewQuestionText('')
    },
  })
  const { mutateAsync: setQuestionPosition, isPending: setPositionPending } = useSetQuestionPosition()

  const runningTask = taskStatuses.find(task => task.resultObjectId === newQuestionId)

  const generateOptimisticQuestionUpdate = (questionValue: string, lastQuestionNotNull?: ConvertedQuestion) => {
    const newQuestion: RfiQuestion = {
      id: 'temp',
      questionText: newQuestionText || questionValue || '',
      rfiCategoryId: lastQuestionNotNull?.id || '',
      rfiId: params.projectId || '',
      proposedAnswer: '',
      childQuestions: [],
      parentId: null,
      latestChildQuestionProposedAnswer: '',
      position: questions.length,
      instructionCategoryId: '',
      rfiCategoryName: '',
      answerComments: [],
      questionComments: [],
    }
    // generate new grouped conversation
    const tempResult = groupAndConvertQuestions([...questions, newQuestion])
    setConvertedQuestions(tempResult)
    setSelectedRfiQuestionId(newQuestion.id)
    setOptimisticQuestion(newQuestion)
    updateQuestionsCache({ rfiId: params.projectId!, proposedAnswer: '', queryClient, rfiQuestionId: newQuestion.id })
    queryClient.setQueriesData({ queryKey: [ApiQueryKeys.RFI_QUESTION, { question_id: newQuestion.id }] }, newQuestion)
    setNewQuestionText('')
  }

  const addNewQuestion = async (questionValue = '') => {
    if (newQuestionText.length === 0 && !questionValue) {
      return
    }

    const lastQuestionNotNull = convertedQuestions.findLast(question => question?.id.toString() !== 'null')
    // create an optimistic question
    generateOptimisticQuestionUpdate(questionValue, lastQuestionNotNull)
    await addRfiQuestion({
      questionText: newQuestionText || questionValue || '',
      rfiCategoryId: lastQuestionNotNull?.id || '',
      rfiId: params.projectId || '',
    })
      .then(data => {
        setTasks(prev => [...prev, { ...data?.data }])
        setNewQuestionId(data?.data.resultObjectId)
        // setNewQuestionText('')
        !questionValue && toggleModal()
        showToast({
          message: 'Adding a new question. Please wait...',
          type: 'success',
          duration: 1000,
        })
      })
      .catch(() => {
        showToast({
          message: 'Failed to add new question',
          type: 'error',
        })
      })
  }

  const handleSavePositions = async (
    overContainer: string,
    prevId: string | null,
    nextId: string | null,
    activeId: string,
  ) => {
    setQuestionPosition({
      prevId,
      nextId,
      activeId,
      rfiCategoryId: overContainer,
    })
  }
  const toggleDragMode = () => setInDragMode(prev => !prev)
  const toggleModal = (content?: ModalState['content']) => {
    setModalState(prev => ({
      open: !prev.open,
      content: content ?? prev.content,
    }))
  }

  // update task running in the background
  useEffect(() => {
    const taskIdsToRemove: string[] = []
    tasks.forEach(currentTask => {
      if (currentTask.resultObjectId !== newQuestionId && !currentTask.completed) {
        const taskStatus = taskStatuses.find(
          task => task.resultObjectId === currentTask.resultObjectId && task.completed,
        )
        if (taskStatus) {
          setTaskResultIds(prev => [...prev, currentTask.resultObjectId])
          taskIdsToRemove.push(currentTask.id)
        }
      }
    })
    if (taskIdsToRemove.length > 0) {
      setTasks(prev => prev.filter(task => !taskIdsToRemove.includes(task.id)))
    }
  }, [tasks, taskStatuses, newQuestionId])

  /*
    update questions cache when question has 
    proposed answer but is not currently selected
  */

  useEffect(() => {
    if (taskResultIds.includes(rfiQuestion.id) && rfiQuestion?.proposedAnswer) {
      setTaskResultIds(prev => prev.filter(task => task !== rfiQuestion.id))
      updateQuestionsCache({
        rfiId: params.projectId!,
        proposedAnswer: rfiQuestion?.proposedAnswer,
        queryClient,
        rfiQuestionId: rfiQuestion.id,
      })
    }
  }, [rfiQuestion, taskResultIds, params.projectId])

  useEffect(() => {
    if (questions.length === 0 || isFetching || isLoading) {
      return
    }
    setConvertedQuestions(groupAndConvertQuestions(questions))
  }, [questions, isFetching, isLoading])

  // new question added to the latest category
  useEffect(() => {
    if (runningTask?.status === 'completed') {
      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.RFI_QUESTIONS] }).then(() => {
        queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.RFIS] })
        setTasks(prev => prev.filter(task => task.resultObjectId !== newQuestionId))
        if (!toastIsBlocked.current) {
          toastIsBlocked.current = true
          showToast({
            message: 'New question added successfully',
            type: 'success',
            duration: 2000,
          })
          setTimeout(() => {
            toastIsBlocked.current = false
          }, 20001)
        }
      })
      setOptimisticQuestion(false)
      if (selectedRfiQuestionId === 'temp' && newQuestionId && selectedRfiQuestionId !== newQuestionId) {
        setSelectedRfiQuestionId(newQuestionId)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runningTask, newQuestionId, selectedRfiQuestionId])

  useEffect(() => {
    if (selectedRfiQuestionId && selectedRfiQuestionId !== 'temp') {
      setSearchParams({ q: selectedRfiQuestionId })
    }
  }, [selectedRfiQuestionId, searchParams, setSearchParams])

  useEffect(() => {
    if (selectedRfiQuestionId === null && convertedQuestions.length > 0 && searchParams.get('q')) {
      setSelectedRfiQuestionId(searchParams.get('q'))
    }
  }, [selectedRfiQuestionId, searchParams, convertedQuestions.length])

  useEffect(() => {
    trackAnalytics(
      {
        type: AnalyticsActionType.page,
        payload: ANALYTICS_EVENTS.ANSWER_SPECIFIC_QUESTION_PAGE_VIEW,
      },
      {
        projectId: params.projectId!,
      },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <RfiQuestionContext.Provider
      value={{
        rfiId: params.projectId!,
        rfiQuestionId: selectedRfiQuestionId,
        setRfiQuestionId: setSelectedRfiQuestionId,
        inDragMode,
        rfiQuestionAction,
        setRfiQuestionAction: action => {
          setRfiQuestionAction(action)
          toggleModal(action.type)
        },
        setInDragMode,
      }}
    >
      <TaskContext.Provider value={{ tasks, setTasks }}>
        <div className={clsx('h-full')}>
          <div className="flex items-center justify-between">
            <WppTypography type="xl-heading">Answer Specific Questions in RFI</WppTypography>
            {hasRequiredPermission(projectMember?.role) && (
              <WppActionButton disabled={hasIncompleteTasks} onClick={toggleDragMode}>
                {inDragMode ? <WppIconDone /> : <WppIconEdit />}
              </WppActionButton>
            )}
          </div>
          <div className="flex flex-row mt-3 items-stretch gap-3 bg-[#FFFFFF] rounded-lg">
            <div className="flex-1 py-[15px] pl-[15px]">
              <div className={clsx(styles.pageContentRight)}>
                {convertedQuestions.length > 0 && (
                  <MultipleContainers
                    disabled={setPositionPending || isPending}
                    questionsFromBe={questions}
                    items={convertedQuestions}
                    setItems={setConvertedQuestions}
                    savePositions={handleSavePositions}
                  />
                )}
              </div>
              {hasRequiredPermission(projectMember?.role) && (
                <div className="mt-10">
                  <WppListItem onClick={() => toggleModal('ASK_QUESTION')}>
                    <WppIconAdd slot="right" />
                    <p slot="label">Add New Question</p>
                  </WppListItem>
                </div>
              )}
            </div>
            <div className={clsx(styles.chatContainer, 'flex-1')}>
              {selectedRfiQuestionId === 'temp' && optimisticQuestion && runningTask?.status !== 'completed' ? (
                <ChatCmpLoading
                  projectId={params.projectId!}
                  taskStatus={runningTask}
                  tempQuestion={optimisticQuestion}
                />
              ) : (
                <ChatCmp addNewQuestion={addNewQuestion} />
              )}
            </div>
          </div>

          <WppModal open={modalState.open} className={styles.modalContainer}>
            {modalState.content === 'ASK_QUESTION' && (
              <>
                <h3 slot="header">Add New Question</h3>
                <p slot="body">
                  <WppInput
                    labelConfig={{ text: 'Enter a new question' }}
                    onWppChange={e => setNewQuestionText(e.detail.value ?? '')}
                    value={newQuestionText}
                    required
                  />
                </p>
                <div className="flex flex-row items-center justify-end gap-2" slot="actions">
                  <WppButton
                    loading={isPending}
                    variant="primary"
                    size="s"
                    onClick={() => {
                      toggleModal()
                      setSelectedRfiQuestionId('')
                      addNewQuestion(newQuestionText)
                    }}
                  >
                    Add
                  </WppButton>
                  <WppButton
                    disabled={isPending}
                    variant="secondary"
                    size="s"
                    onClick={() => {
                      toggleModal()
                      setNewQuestionText('')
                    }}
                  >
                    Close
                  </WppButton>
                </div>
              </>
            )}
          </WppModal>
        </div>
      </TaskContext.Provider>
    </RfiQuestionContext.Provider>
  )
}
