import {
  WppIconChatMessage,
  WppIconDocument,
  WppIconHome,
  WppIconLogout,
  WppIconPitch,
  WppNavSidebarItem,
} from '@wppopen/components-library-react'
import clsx from 'clsx'

export interface NavigationItemProps {
  navigation: { [key: string]: string }
  disableItems: string
  projectId: string
  active: string
  newTaskStarted: boolean
}

export const NavigationItems = ({
  navigation,
  newTaskStarted,
  active,
  disableItems,
  projectId,
}: NavigationItemProps) => {
  if (!active) {
    return null
  }

  const selectProjectDetails = active.includes(navigation.projectDetails) || active.includes(navigation.newProject)

  return (
    <>
      <WppNavSidebarItem
        className={clsx(newTaskStarted && 'opacity-50 hover:cursor-not-allowed pointer-events-none')}
        active={active.includes(navigation.home)}
        label="My Projects"
        path={navigation.home}
        divide
      >
        <WppIconHome slot="icon-start" />
      </WppNavSidebarItem>
      <WppNavSidebarItem
        className={!selectProjectDetails ? disableItems : ''}
        active={selectProjectDetails}
        label="Project Details"
        path={`${navigation.projectDetails}/${projectId}`}
      >
        <WppIconLogout slot="icon-start" className="-rotate-90" />
      </WppNavSidebarItem>
      <WppNavSidebarItem
        className={disableItems}
        active={active.includes(navigation.rfiSummary)}
        label="Summarise RFI / RFP"
        path={`${navigation.rfiSummary}/${projectId}`}
      >
        <WppIconDocument slot="icon-start" />
      </WppNavSidebarItem>
      <WppNavSidebarItem
        className={disableItems}
        active={active.includes(navigation.relevantNotes)}
        maxTitleLengthWithoutSubItems={27}
        label="Relevant Cases and Pitches"
        path={`${navigation.relevantNotes}/${projectId}`}
      >
        <WppIconPitch slot="icon-start" />
      </WppNavSidebarItem>
      <WppNavSidebarItem
        className={disableItems}
        active={active.includes(navigation.answerQuestions)}
        maxTitleLengthWithoutSubItems={27}
        label="Answer Specific Questions"
        path={`${navigation.answerQuestions}/${projectId}`}
      >
        <WppIconChatMessage slot="icon-start" />
      </WppNavSidebarItem>
      <WppNavSidebarItem
        className={disableItems}
        active={active.includes(navigation.draftResponse)}
        label="Ai Draft Response"
        path={`${navigation.draftResponse}/${projectId}`}
      >
        <div slot="icon-start">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.522165 6.17972C-0.174122 6.40534 -0.173988 7.39033 0.522165 7.61591L3.04341 8.4329C4.14367 8.78943 5.00621 9.65197 5.36273 10.7522L6.17972 13.2735C6.40535 13.9698 7.39034 13.9696 7.61592 13.2735L8.43291 10.7522C8.78944 9.65197 9.65198 8.78943 10.7522 8.4329L13.2735 7.61591C13.9698 7.39029 13.9696 6.4053 13.2735 6.17972L10.7522 5.36273C9.65198 5.0062 8.78944 4.14366 8.43291 3.04341L7.61592 0.522164C7.39029 -0.174122 6.40531 -0.173988 6.17972 0.522164L5.36274 3.04341C5.00621 4.14366 4.14367 5.0062 3.04341 5.36273L0.522165 6.17972ZM3.50544 7.00707L3.16829 6.89781L3.50544 6.78856C5.06291 6.28388 6.28389 5.06291 6.78857 3.50544L6.89782 3.16829L7.00707 3.50544C7.51176 5.06291 8.73273 6.28388 10.2902 6.78856L10.6274 6.89781L10.2902 7.00707C8.73273 7.51175 7.51176 8.73272 7.00707 10.2902L6.89782 10.6273L6.78857 10.2902C6.28389 8.73272 5.06291 7.51175 3.50544 7.00707ZM12.3841 13.9036C13.0564 13.5957 13.5961 13.056 13.9039 12.3837C14.2118 13.056 14.7515 13.5957 15.4238 13.9036C14.7515 14.2114 14.2118 14.7511 13.9039 15.4234C13.5961 14.7511 13.0564 14.2114 12.3841 13.9036ZM14.501 10.2416C14.3134 9.66282 13.4945 9.66277 13.3069 10.2416L12.8456 11.6652C12.6642 12.225 12.2254 12.6638 11.6656 12.8452L10.242 13.3065C9.66321 13.4941 9.66316 14.313 10.242 14.5006L11.6656 14.9619C12.2254 15.1433 12.6642 15.5821 12.8456 16.1419L13.3069 17.5655C13.4945 18.1443 14.3134 18.1443 14.501 17.5655L14.9623 16.1419C15.1437 15.5821 15.5825 15.1433 16.1423 14.9619L17.5659 14.5006C18.1447 14.313 18.1447 13.4941 17.5659 13.3065L16.1423 12.8452C15.5825 12.6638 15.1437 12.225 14.9623 11.6652L14.501 10.2416Z"
              fill="#343A3F"
            />
          </svg>
        </div>
      </WppNavSidebarItem>
    </>
  )
}
