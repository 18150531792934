import { WppDivider, WppTypography } from '@wppopen/components-library-react'
import clsx from 'clsx'

import { LoaderProgressWithDescription, ProgressApiRes } from '../LoaderProgressWithDescription'
import styles from './Chat.module.scss'
import { ConversationMessage } from './conversation-message/ConversationMessage'
import { QuestionInputNew } from './question-input/QuestionInputNew'
import { MessageType } from './types'
import { RfiQuestion } from '@/types/rfis/rfi'

export const ChatCmpLoading = ({
  tempQuestion,
  taskStatus,
  projectId,
}: {
  taskStatus?: ProgressApiRes
  tempQuestion: RfiQuestion
  projectId: string
}) => {
  return (
    <div
      className={clsx(
        styles.chatContainer,
        'flex flex-col gap-2 rounded-[10px] w-full max-w-[650px] h-full justify-between',
      )}
    >
      <div>
        <WppTypography type="m-strong">{tempQuestion?.questionText}</WppTypography>
        <WppDivider />
      </div>

      <div className="mb-auto h-full flex flex-col justify-between gap-4">
        <div className={clsx(styles.chatDisplay, 'flex flex-col gap-3')}>
          <div>
            <ConversationMessage
              projectId={projectId}
              message={{
                type: MessageType.QUESTION,
                id: 'tempMessage',
                content: tempQuestion?.questionText,
                timestamp: Date.now().toString(),
                rfiQuestionId: tempQuestion?.id,
                comments: [],
              }}
              isTaskRunning={true}
              setConversation={() => null}
              projectMembers={[]}
              handleDeleteComment={() => null}
              handleUpdateCommentList={() => null}
            />
          </div>
          <div className={clsx('flex flex-row items-start justify-center py-4 bg-[#f3f3f3] rounded mt-6')}>
            <LoaderProgressWithDescription taskStatus={taskStatus ?? null} />
          </div>
        </div>

        <div className={styles.questionInputWrapper}>
          <div className={styles.inputBorder}>
            <QuestionInputNew
              onInput={() => null}
              disabled
              question=""
              answerIsLoading={false}
              handleSubmit={() => null}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
