import { ToastState } from '@wppopen/components-library'
import { WppIconFile, WppTypography } from '@wppopen/components-library-react'

import { WppControlledUpload, FileUploadChangeProps } from 'components/wpp-controlled-upload/WppControlledUpload'

export interface UseCaseEditAddFileProps {
  existingFileUrl: string | null
  existingFileName: string | null
  isEditMode: boolean
  onFileUploadChange: (params: FileUploadChangeProps) => void
  toast: {
    showToast: (config: ToastState) => void
  }
  wppFile: File[] | undefined
  disabled?: boolean
}

export const UseCaseEditAddFile = ({
  existingFileUrl,
  existingFileName,
  isEditMode,
  onFileUploadChange,
  toast,
  wppFile,
  disabled = false,
}: UseCaseEditAddFileProps) => {
  return (
    <>
      <WppControlledUpload
        disabled={disabled}
        name="description"
        acceptConfig={{ 'application/pdf': ['.pdf'] }}
        onFileUploadChange={onFileUploadChange}
        locales={{
          label: 'File upload',
          text: 'Drag and drop a file here or click to upload',
          info: (accept, size) => `Accepted file types: ${accept}. Max file size: ${size}MB`,
          sizeError: 'File Size Limit Exceeded',
          formatError: 'Unsupported file extension',
        }}
        onError={() => {
          toast.showToast({
            message: 'Your file upload was not successful',
            type: 'error',
          })
        }}
        format="arrayBuffer"
        size={500}
        value={wppFile || []}
      />

      {isEditMode && existingFileUrl && (wppFile?.length === 0 || wppFile === null) && (
        <div className="flex gap-2 bg-[#F4F5F7] rounded-6 p-3">
          <WppIconFile />
          <WppTypography type="s-body" className="underline text-[#343A3F]">
            {/*replace with file name from api after it's updated*/}
            {existingFileName}
          </WppTypography>
          <WppTypography type="s-body" className="text-[#697077]">
            {/* file size should be from the api */}
          </WppTypography>
        </div>
      )}
    </>
  )
}
