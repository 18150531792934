import { createUseQuery } from 'api/common/createUseQuery'
import { fetchFileDownloadUrls } from 'api/fetchers/files/fetchFileDownloadUrls'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

import { DownloadUrl } from '@/types/files/downloadUrl'

export const useFileDownloadUrls = createUseQuery({
  queryKey: ApiQueryKeys.FILE_DOWNLOAD_URLS,
  fetcher: fetchFileDownloadUrls,
  selector: res => res?.data ?? ([] as DownloadUrl[]),
})
