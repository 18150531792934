export interface UseCase {
  id: string
  rfiId: string
  title: string
  markets: string[]
  client: string
  clientId: string
  pitchTypes: string[]
  isFavorite: boolean
  isConfidential: boolean
  description: string | null
  subCategory: string
  summary: string
  fileUrl: string
  fileKey: string
  fileName: string | null
  fileSize: number | null
  keywords: string[]
  createdBy: string

  createdAt: string
  agencyIds?: string[]
}

export const UseCasesFilterKeys = [
  'filter[markets]',
  'filter[client]',
  'filter[pitchTypes]',
  'filter[agencyIds]',
  'filter[createdBy]',
  'filter[subCategory]',
  'filter[isConfidential]',
] as const

type FilterOptionalKeys = { [K in (typeof UseCasesFilterKeys)[number]]?: string | null }

interface ExtraParams {
  'search[name]'?: string | null
  order?: string | null
  rfiId?: string
  pitchTypeIds?: string[]
}

export type FetchUseCasesFiltersParams = FilterOptionalKeys & ExtraParams

export interface UseCasesMeta {
  clients: { name: string; id: string }[]
  markets: { name: string; id: string }[]
  pitches: { id: string; name: string }[]
  createdBys: { name: string; email: string }[]
  updatedBys: { name: string; email: string }[]
  agencies: { name: string; id: string }[]
  subCategories: { name: string; id: string }[]
}
