import { rfiHelperApi } from 'api'
import { AgencyCategoriesMergePreview } from 'types/agency-categories/agency-categories'

export const getAgencyCategoriesMergePreview = (params: {
  agencyId: string
  fileId: string
  contentStrategy?: string
}) =>
  rfiHelperApi.get<AgencyCategoriesMergePreview>(
    `/instructions-categories/merge/preview/${params.agencyId}/${params.fileId}?agency_file_id=${encodeURIComponent(params.fileId)}&merge_strategy=${encodeURIComponent(params.contentStrategy ?? 'merge')}`,
  )
