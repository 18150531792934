import segmentPlugin from '@analytics/segment'
import { AnalyticsAction, AnalyticsPageAction, AnalyticsTrackAction, AnalyticsActionType } from '@wppopen/core'
import Analytics from 'analytics'

import { excludeFalsy, isString } from 'utils/common'

import { segmentAnalyticsKey } from '../constants/segmentConfig'

export const analytics = Analytics({
  app: 'wpp-rfi-helper-fe',
  plugins: [
    segmentPlugin({
      writeKey: segmentAnalyticsKey,
    }),
  ].filter(excludeFalsy),
})

export const reset = () => {
  analytics.reset()
}

const getAnalyticsTenantAzId = () => analytics?.getState()?.user?.traits?.tenantAzId

const trackPage = ({ payload }: AnalyticsPageAction, extraPayload?: Record<string, any>) => {
  const analyticsTenantAzId = getAnalyticsTenantAzId()
  if (analyticsTenantAzId) {
    analytics.page(
      {
        title: payload,
        ...extraPayload,
      },
      {
        agency: analyticsTenantAzId,
      },
    )
  }
}

const trackAction = ({ payload }: AnalyticsTrackAction, extraPayload?: Record<string, any>) => {
  const analyticsTenantAzId = getAnalyticsTenantAzId()
  if (analyticsTenantAzId) {
    if (isString(payload)) {
      analytics.track(payload, {
        agency: analyticsTenantAzId,
        ...extraPayload,
      })
    } else {
      const { action, params } = payload
      const withExtraPayload = { ...Object.fromEntries(params.map(({ key, value }) => [key, value])), ...extraPayload }
      analytics.track(action, {
        agency: analyticsTenantAzId,
        ...withExtraPayload,
      })
    }
  }
}

export const trackAnalytics = (action: AnalyticsAction, extraPayload?: Record<string, any>) => {
  switch (action.type) {
    case AnalyticsActionType.page:
      trackPage(action, extraPayload)
      break

    case AnalyticsActionType.action:
      trackAction(action, extraPayload)
      break

    default:
      break
  }
}

export const ANALYTICS_EVENTS = {
  MY_PROJECTS_PAGE_VIEW: 'rfi_pv_welcome_screen',
  PROJECT_DETAILS_PAGE_VIEW: 'rfi_pv_project_details',
  SUMMARY_PAGE_VIEW: 'rfi_pv_summarize',
  RELEVANT_CASES_PAGE_VIEW: 'rfi_pv_relevant_cases',
  ANSWER_SPECIFIC_QUESTION_PAGE_VIEW: 'rfi_pv_answer_questions',
  AI_DRAFT_RESPONSE_PAGE_VIEW: 'rfi_pv_draft_response',
  NEW_PROJECT_CREATED_ACTION: 'rfi_act_project_created',
  NEW_PROJECT_BUTTON_CLICK_ACTION: 'rfi_act_project_create_button',
  PROJECT_EXPORT: 'rfi_act_project_export',
  POST_COMMENT: 'rfi_act_comment_posted',
  POST_QUERY: 'rfi_act_query_sent',
  ANSWERED_QUESTION: 'rfi_act_question_answered',
}
