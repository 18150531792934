import { createUseQuery } from 'api/common/createUseQuery'
import { fetchUseCasessMeta } from 'api/fetchers/use-cases/fetchUseCasesMeta'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

import { UseCasesMeta } from '@/types/use-cases/useCase'

export const useUseCasesMeta = createUseQuery({
  queryKey: ApiQueryKeys.USE_CASES_META,
  fetcher: fetchUseCasessMeta,
  selector: res => res?.data ?? ({} as UseCasesMeta),
})
