import axios from 'axios'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import { useEffect, useState, useCallback } from 'react'

import { useFileDownloadUrls } from 'api/queries/files/useFileDownloadUrls'
import { useToast } from 'hooks/useToast'

import { DownloadUrl } from '@/types/files/downloadUrl'

export interface FileObject {
  key: string
  name: string | null
}
interface CallbackParams {
  fileObjects: FileObject[]
  downloadFile?: boolean
  folderName?: string
}
export type FileViewOrDownloadReturn = [isFetchingFile: boolean, (params: CallbackParams) => void]

export const useFileViewOrDownload = (customErrorMessage?: string): FileViewOrDownloadReturn => {
  const toast = useToast()
  const [fileObjects, setFileObjects] = useState<FileObject[]>([])
  const [folderName, setFolderName] = useState<string | undefined>(undefined)
  const {
    data: fileUrls,
    isLoading,
    isError,
  } = useFileDownloadUrls({
    params: { file_keys: fileObjects.map(fileObject => fileObject.key) },
    enabled: !!fileObjects.length,
  })
  const [isFetchingFile, setIsFetchingFile] = useState(false)

  const showError = useCallback(
    (params?: { customMessage?: string; type?: 'error' | 'information' | 'warning' }) => {
      toast.showToast({ message: params?.customMessage ?? 'Failed to download file', type: params?.type ?? 'error' })
    },
    [toast],
  )

  const resetFilesState = useCallback(() => {
    setFileObjects([])
    setFolderName(undefined)
  }, [])

  const viewOrDownloadFile = useCallback(
    ({ fileObjects, downloadFile, folderName }: CallbackParams) => {
      if (isFetchingFile) return
      if (fileObjects.length === 1) {
        setFileObjects([{ ...fileObjects[0], name: downloadFile ? fileObjects[0].name : null }])
      } else {
        setFileObjects(fileObjects)
      }

      setFolderName(folderName)
    },
    [isFetchingFile],
  )

  const getFile = useCallback(
    async (url: string | null, downloadFileName: string | null) => {
      if (url === null) {
        showError({ customMessage: customErrorMessage ?? 'Error getting the file. It might have been deleted.' })
        setIsFetchingFile(false)
        return
      }
      setIsFetchingFile(true)
      const link = document.createElement('a')
      let blob: Blob | null = null
      if (downloadFileName) {
        link.download = downloadFileName
        try {
          const response = await fetch(url)

          if (!response.ok) {
            setIsFetchingFile(false)
            showError()
            return false
          }
          blob = await response.blob()
        } catch (e) {
          setIsFetchingFile(false)
          showError()
        }
      }
      link.href = blob !== null ? URL.createObjectURL(blob) : url
      link.target = '_blank'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      setIsFetchingFile(false)
    },
    [showError, customErrorMessage],
  )

  const downloadAndZipFiles = useCallback(
    async (files: typeof fileUrls, fileObjects: FileObject[], folderName?: string) => {
      const validFiles: DownloadUrl[] = []
      const validFileNames: (string | null)[] = []
      const fileNamesWithError: string[] = []
      files.forEach((file, index) => {
        if (file.signedUrl) {
          validFiles.push(file)
          validFileNames.push(fileObjects[index].name)
        } else {
          if (fileObjects[index].name) {
            fileNamesWithError.push(fileObjects[index].name)
          }
        }
      })
      if (validFiles.length === 0) {
        showError({ customMessage: 'Something went wrong while downloading the files. Please try again.' })
        setIsFetchingFile(false)
        return
      }
      setIsFetchingFile(true)
      const zip = new JSZip()
      const pdfFolder = zip.folder(folderName ?? 'files')

      const fetchFile = async (url: string) => {
        const response = await axios.get(url, {
          responseType: 'blob',
        })
        if (response.status !== 200) {
          showError({ customMessage: 'Something went wrong while downloading the files. Please try again.' })

          setIsFetchingFile(false)
          return
        }
        return response.data
      }
      const fileNamesUsed: string[] = []

      const promises = validFiles.map(async (file, index) => {
        const fileData = await fetchFile(file.signedUrl!)
        let name = validFileNames[index] ?? `file${index + 1}.pdf`
        let nameCounter = 0
        fileNamesUsed.forEach(nameUsed => {
          if (nameUsed === name) {
            nameCounter++
          }
        })
        fileNamesUsed.push(name)
        const fileName = nameCounter > 0 ? `${name.replace('.pdf', '')} (${nameCounter}).pdf` : name
        pdfFolder && pdfFolder.file(fileName, fileData)
      })

      await Promise.all(promises)

      const content = await zip.generateAsync({ type: 'blob' })

      saveAs(content, `${folderName ?? 'files'}.zip`)
      if (fileNamesWithError.length > 0) {
        showError({
          customMessage: `The following files could not be downloaded: ${fileNamesWithError.join(', ')}`,
          type: 'warning',
        })
      }
      setIsFetchingFile(false)
    },
    [showError],
  )

  useEffect(() => {
    if (isError) {
      resetFilesState()
      setIsFetchingFile(false)
      showError()
    }
  }, [isError, showError, resetFilesState])

  useEffect(() => {
    if (isLoading) {
      setIsFetchingFile(true)
    }
  }, [isLoading])

  useEffect(() => {
    if (fileUrls.length > 0) {
      resetFilesState()
      if (fileUrls.length === 1) {
        getFile(fileUrls[0].signedUrl ?? null, fileObjects[0].name)
      } else if (fileUrls.length > 1) {
        downloadAndZipFiles(fileUrls, fileObjects, folderName)
      }
    }
  }, [fileUrls, fileObjects, folderName, getFile, downloadAndZipFiles, resetFilesState])

  return [isFetchingFile, viewOrDownloadFile]
}
