import {
  WppButton,
  WppCheckbox,
  WppDivider,
  WppIconWarning,
  WppInlineMessage,
  WppSpinner,
  WppTooltip,
  WppTypography,
} from '@wppopen/components-library-react'
import clsx from 'clsx'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { useMergeAgencyCategories } from 'api/mutations/agencyCategories/useMergeAgencyCategories'
import { useGetAgencyCategoriesMergePreview } from 'api/queries/agency-categories/useGetAgencyCategoriesMergePreview'
import { useTasksStatus } from 'api/queries/task-status/useTasksStatus'
import { useToast } from 'hooks/useToast'

import { AgencyCategoriesMergePreview } from '@/types/agency-categories/agency-categories'

interface Props {
  agencyId: string
  fileName: string | null | undefined
  fileStatusTaskId: string
  emptyKnowledgeBase: boolean
  contentStrategy?: 'merge' | 'replace'
  handleSave: () => void
  handleCancel: () => void
  handleError: () => void
  paddingContentTop?: string
  withContentDividerTop?: boolean
}

export const KnowledgeBaseMergeSelection = ({
  agencyId,
  fileName,
  fileStatusTaskId,
  emptyKnowledgeBase,
  contentStrategy = 'merge',
  handleSave,
  handleCancel,
  handleError,
  paddingContentTop = '0px',
  withContentDividerTop = true,
}: Props) => {
  const [fileId, setFileId] = useState<string | null>(null)
  const { data: taskStatus } = useTasksStatus({
    params: { taskId: fileStatusTaskId },
    enabled: !!fileStatusTaskId && !fileId,
    refetchInterval: 5000,
  })

  const { data: knowledgeBase } = useGetAgencyCategoriesMergePreview({
    params: { agencyId, fileId: fileId!, contentStrategy },
    enabled: !!fileId,
  })

  const potentialChanges = useMemo(() => {
    if (!knowledgeBase) return []
    return transformContentToChanges(knowledgeBase, contentStrategy)
  }, [contentStrategy, knowledgeBase])

  const [isSaving, setIsSaving] = useState(false)
  const [isRetry, setIsRetry] = useState(false)
  const [selectedChanges, setSelectedChanges] = useState<KnowledgeBaseChange[]>([])

  const toast = useToast()
  const { mutateAsync: mergeCategories } = useMergeAgencyCategories({
    onError: error => {
      toast.showToast({
        message: error.message,
        type: 'error',
      })
      handleCancel()
    },
  })

  useEffect(() => {
    if (taskStatus) {
      if (taskStatus.error || (taskStatus.completed && !taskStatus.resultObjectId)) {
        toast.showToast({
          message: 'Your file upload was not successful',
          type: 'error',
        })
        handleError()
        return
      } else if (taskStatus.completed && taskStatus.resultObjectId) {
        setFileId(taskStatus.resultObjectId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskStatus])

  const mapSelectedChangesToMerge = (selectedContent: KnowledgeBaseChange[]) => {
    const mergeContentIds = new Set<string>()
    for (const item of selectedContent) {
      if (item.change !== 'merge') continue
      mergeContentIds.add(item.id)
      if (item.parentId) {
        mergeContentIds.add(item.parentId)
      }
    }
    return Array.from(mergeContentIds.values())
  }

  const mapSelectedChangesToDelete = (selectedContent: KnowledgeBaseChange[]) => {
    const deleteContentIds = new Set<string>()
    for (const item of selectedContent) {
      if (item.change !== 'delete') continue
      deleteContentIds.add(item.id)
      const parentItem = potentialChanges.find(i => i.id === item.parentId)
      if (parentItem && categoryItemIsChecked(parentItem) && parentItem.id) {
        deleteContentIds.add(parentItem.id)
      }
    }
    return Array.from(deleteContentIds.values())
  }

  const onHandleSave = async () => {
    setIsSaving(true)

    try {
      await mergeCategories({
        fileId: fileId!,
        categoryIds: mapSelectedChangesToMerge(selectedChanges),
        deleteCategoryIds: mapSelectedChangesToDelete(selectedChanges),
      })

      toast.showToast({
        message: "You've successfully added file.",
        type: 'success',
      })
      setIsSaving(false)
      handleSave()
    } catch {
      toast.showToast({
        message: 'Your file upload was not successful',
        type: 'error',
      })
      setIsSaving(false)
      setIsRetry(true)
      handleError()
    }
  }

  const addSelectedChange = (
    currentSelectedChanges: KnowledgeBaseChange[],
    item: KnowledgeBaseSubcategoryItem,
    parentItem: KnowledgeBaseCategoryItem,
  ) => {
    if (!item.id) {
      console.error('Item without proper id', item)
      return currentSelectedChanges
    }

    const found = selectedChanges.find(i => i.id === item.id)
    if (found) return currentSelectedChanges

    let newSelectedChanges = [...currentSelectedChanges]

    newSelectedChanges.push({ change: item.isToDelete ? 'delete' : 'merge', id: item.id, parentId: parentItem.id })

    return newSelectedChanges
  }

  const removeSelectedChange = (currentSelectedChanges: KnowledgeBaseChange[], item: KnowledgeBaseSubcategoryItem) => {
    if (!item.id) {
      console.error('Item without proper id', item)
      return currentSelectedChanges
    }

    return [...currentSelectedChanges].filter(i => i.id !== item.id)
  }

  const toggleSelectedChange = (
    currentSelectedChanges: KnowledgeBaseChange[],
    item: KnowledgeBaseSubcategoryItem,
    parentItem: KnowledgeBaseCategoryItem,
  ) => {
    if (!item.id) {
      console.error('Item without proper id', item)
      return currentSelectedChanges
    }

    const found = currentSelectedChanges.find(i => i.id === item.id)
    if (found) {
      return removeSelectedChange(currentSelectedChanges, item)
    } else {
      return addSelectedChange(currentSelectedChanges, item, parentItem)
    }
  }

  const categoryItemIsChecked = useCallback(
    (item: KnowledgeBaseCategoryItem) => {
      if (!item.id) return false
      const selectedSubCategories = selectedChanges.filter(i => i.parentId === item.id)
      const subCategoriesWithIncomingChanges = item.subCategories.filter(i => i.hasIncomingChange)

      return selectedSubCategories.length === subCategoriesWithIncomingChanges.length
    },
    [selectedChanges],
  )

  const onHandleChecked = (item: KnowledgeBaseItem, parentItem?: KnowledgeBaseCategoryItem) => {
    let newSelectedChanges = [...selectedChanges]
    if (item.type === 'category') {
      if (categoryItemIsChecked(item)) {
        for (const subCategory of item.subCategories) {
          newSelectedChanges = removeSelectedChange(newSelectedChanges, subCategory)
        }
      } else {
        for (const subCategory of item.subCategories) {
          newSelectedChanges = addSelectedChange(newSelectedChanges, subCategory, item)
        }
      }
    } else if (item.type === 'sub-category') {
      if (!parentItem) return
      newSelectedChanges = toggleSelectedChange(selectedChanges, item, parentItem)
    }
    setSelectedChanges(newSelectedChanges)
  }

  const allCategoriesChecked = useMemo(() => {
    return potentialChanges.every(i => categoryItemIsChecked(i))
  }, [categoryItemIsChecked, potentialChanges])

  const onHandleCheckAll = () => {
    if (!allCategoriesChecked) {
      let newSelectedChanges = [...selectedChanges]
      for (const category of potentialChanges) {
        for (const subCategory of category.subCategories) {
          if (!subCategory.hasIncomingChange) continue
          newSelectedChanges = addSelectedChange(newSelectedChanges, subCategory, category)
        }
      }
      setSelectedChanges(newSelectedChanges)
    } else {
      setSelectedChanges([])
    }
  }

  return (
    <>
      {withContentDividerTop && <WppDivider />}

      <div
        className="flex flex-col gap-6 px-8 overflow-y-auto py-6"
        style={{
          minHeight: `calc(100vh - calc(158px + ${paddingContentTop}))`,
          height: `calc(100vh - calc(158px + ${paddingContentTop}))`,
        }}
      >
        <div className="flex flex-col gap-2">
          <WppTypography type="xl-heading">{fileName ?? 'Untitled file'}</WppTypography>
          <WppTypography type="s-body">
            Preview what is being imported and make a selection to confirm the import. The content that is not selected
            will not be imported and therefore not added in the knowledge base.
          </WppTypography>
          {contentStrategy === 'replace' && (
            <div className="py-6">
              <WppInlineMessage
                size="s"
                message="This action will replace all content for identified category."
                type="warning"
              />
            </div>
          )}
        </div>

        <WppDivider />

        {(!taskStatus || !taskStatus.completed) && (
          <>
            <div className="justify-center items-center w-full flex flex-col h-full">
              <WppSpinner size="l" />
              <p>{taskStatus?.status}</p>
            </div>
          </>
        )}

        {taskStatus?.completed && (
          <div className="flex flex-col">
            <div className={clsx('grid', emptyKnowledgeBase ? 'grid-cols-1' : 'grid-cols-2')}>
              {!emptyKnowledgeBase && (
                <div className="flex items-center w-full pr-4 border-r border-solid border-grey50">
                  <span className="uppercase text-10 font-bold">Current Knowledge Base content</span>
                </div>
              )}
              <div className={clsx('flex justify-between items-center w-full', !emptyKnowledgeBase && 'pl-4')}>
                <span className="uppercase text-10 font-bold">
                  {emptyKnowledgeBase
                    ? 'Select Content'
                    : contentStrategy === 'merge'
                      ? 'Merged content'
                      : 'New File content'}
                </span>
                <WppButton variant="secondary" size="s" disabled={isSaving} onClick={onHandleCheckAll}>
                  {allCategoriesChecked ? 'Deselect All' : 'Select All'}
                </WppButton>
              </div>
            </div>

            <div className="flex flex-col">
              {potentialChanges.map(parentCategory => {
                return (
                  <div key={parentCategory.key}>
                    <div className={clsx('grid', emptyKnowledgeBase ? 'grid-cols-1' : 'grid-cols-2')}>
                      {!emptyKnowledgeBase && (
                        <div className="flex items-center w-full pr-4 border-r border-solid border-grey50 pt-6">
                          {!parentCategory.isNew && <h5 className="font-semibold text-xl">{parentCategory.name}</h5>}
                        </div>
                      )}
                      <div
                        className={clsx('flex justify-between items-center w-full pt-6', !emptyKnowledgeBase && 'pl-4')}
                      >
                        {!parentCategory.isToDelete && <h5 className="font-semibold text-xl">{parentCategory.name}</h5>}
                        {parentCategory.isToDelete && (
                          <div className="flex items-center gap-x-2">
                            <h5 className="font-semibold text-xl text-[#FF143C]">
                              This section has no content in the new file
                            </h5>
                            <WppTooltip
                              warning
                              text="Warning: Selecting this section will overwrite and delete the existing content for this category."
                            >
                              <WppIconWarning className="ml-2 w-5 h-5" />
                            </WppTooltip>
                          </div>
                        )}
                        {parentCategory.hasIncomingChange && (
                          <WppCheckbox
                            onWppChange={_ => onHandleChecked(parentCategory)}
                            checked={categoryItemIsChecked(parentCategory)}
                            controlled
                          />
                        )}
                      </div>
                    </div>

                    {parentCategory.subCategories.map(subCategory => {
                      return (
                        <div key={subCategory.key}>
                          <div className={clsx('grid', emptyKnowledgeBase ? 'grid-cols-1' : 'grid-cols-2')}>
                            {!emptyKnowledgeBase && (
                              <div className="flex items-center w-full pr-4 border-r border-solid border-grey50 pt-6">
                                {!subCategory.isNew && <h6 className="font-semibold text-lg">{subCategory.name}</h6>}
                              </div>
                            )}
                            <div
                              className={clsx(
                                'flex justify-between items-center w-full pt-6',
                                !emptyKnowledgeBase && 'pl-4',
                              )}
                            >
                              {!subCategory.isToDelete && <h6 className="font-semibold text-lg">{subCategory.name}</h6>}
                              {subCategory.isToDelete && (
                                <div className="flex items-center gap-x-2">
                                  <h6 className="font-semibold text-lg text-[#FF143C]">
                                    This section has no content in the new file
                                  </h6>
                                  <WppTooltip
                                    warning
                                    text="Warning: Selecting this section will overwrite and delete the existing content for this category."
                                  >
                                    <WppIconWarning className="ml-2 w-5 h-5" />
                                  </WppTooltip>
                                </div>
                              )}
                              {subCategory.hasIncomingChange && (
                                <WppCheckbox
                                  onWppChange={_ => onHandleChecked(subCategory, parentCategory)}
                                  checked={selectedChanges.some(i => i.id === subCategory.id)}
                                  controlled
                                />
                              )}
                            </div>
                          </div>
                          <div className={clsx('grid', emptyKnowledgeBase ? 'grid-cols-1' : 'grid-cols-2')}>
                            {!emptyKnowledgeBase && (
                              <div className="flex items-start w-full pr-4 border-r border-solid border-grey50 pt-4">
                                {!subCategory.isNew && (
                                  <p
                                    className="break-normal"
                                    dangerouslySetInnerHTML={{ __html: subCategory.previousContent ?? '' }}
                                  />
                                )}
                              </div>
                            )}
                            <div className={clsx('flex items-start w-full pt-4', !emptyKnowledgeBase && 'pl-4')}>
                              {!subCategory.isToDelete && (
                                <p
                                  className="break-normal"
                                  dangerouslySetInnerHTML={{
                                    __html: subCategory.newContent ?? subCategory.previousContent ?? '',
                                  }}
                                />
                              )}
                              {subCategory.isToDelete && (
                                <div className="w-full h-full flex justify-center items-center border-solid border border-[#FF143C] text-[#FF143C] rounded-8">
                                  This section has no content.
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>

      <WppDivider />

      <div className="flex justify-between items-center px-8 pt-4 pb-6">
        <div className="font-semibold">Selected: {selectedChanges.length}</div>
        <div className="flex gap-4">
          <WppButton onClick={handleCancel} variant="secondary" disabled={isSaving}>
            Cancel
          </WppButton>
          <WppButton
            onClick={() => {
              onHandleSave()
            }}
            disabled={isSaving || selectedChanges.length === 0}
          >
            {isRetry ? 'Retry' : isSaving ? 'Saving...' : 'Confirm Selection'}
          </WppButton>
        </div>
      </div>
    </>
  )
}

interface KnowledgeBaseChange {
  change: 'delete' | 'merge'
  id: string
  parentId: string | null | undefined
}

type KnowledgeBaseItem = KnowledgeBaseCategoryItem | KnowledgeBaseSubcategoryItem

interface KnowledgeBaseSubcategoryItem {
  type: 'sub-category'
  id: string | null
  key: string
  name: string
  previousId: string | null
  previousContent: string | null
  newId: string | null
  newContent: string | null
  isNew: boolean
  hasIncomingChange: boolean
  isToDelete: boolean
}

interface KnowledgeBaseCategoryItem {
  type: 'category'
  id: string | null
  key: string
  name: string
  previousId: string | null
  newId: string | null
  subCategories: KnowledgeBaseSubcategoryItem[]
  isNew: boolean
  hasIncomingChange: boolean
  isToDelete: boolean
}

function transformContentToChanges(
  content: AgencyCategoriesMergePreview,
  contentStrategy: 'replace' | 'merge',
): KnowledgeBaseCategoryItem[] {
  const parentCategories = (content?.mergeContent || []).filter(
    item => !item.stageParentCategoryId && !item.mainParentCategoryId,
  )

  return parentCategories.map(parentCategory => {
    const subCategories = (content?.mergeContent || [])
      .filter(
        i =>
          (i.mainParentCategoryId && i.mainParentCategoryId === parentCategory.mainCategoryId) ||
          (i.stageParentCategoryId && i.stageParentCategoryId === parentCategory.stageCategoryId),
      )
      .map(subCategory => {
        const isToDelete = !subCategory.stageCategoryId && contentStrategy === 'replace'
        return {
          type: 'sub-category' as const,
          key: `sub-category-${subCategory.mainCategoryId}-${subCategory.stageCategoryId}`,
          name: subCategory.name,
          previousId: subCategory.mainCategoryId,
          previousContent: subCategory.currentInstruction,
          newId: subCategory.stageCategoryId,
          newContent: subCategory.incomingInstruction,
          isNew: !subCategory.mainCategoryId,
          hasIncomingChange: !!subCategory.stageCategoryId || isToDelete,
          isToDelete,
          id: isToDelete ? subCategory.mainCategoryId : subCategory.stageCategoryId,
        }
      })
    const isToDelete = subCategories.every(i => i.isToDelete)
    return {
      type: 'category' as const,
      key: `category-${parentCategory.mainCategoryId}-${parentCategory.stageCategoryId}`,
      name: parentCategory.name,
      previousId: parentCategory.mainCategoryId,
      newId: parentCategory.stageCategoryId,
      subCategories,
      isNew: !parentCategory.mainCategoryId,
      hasIncomingChange: subCategories.some(i => i.hasIncomingChange),
      isToDelete,
      id: isToDelete ? parentCategory.mainCategoryId : parentCategory.stageCategoryId,
    }
  })
}
