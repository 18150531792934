import {
  WppIconFile,
  WppTypography,
  WppIconDownload,
  WppSpinner,
  WppActionButton,
  WppTooltip,
} from '@wppopen/components-library-react'

import { useFileViewOrDownload } from 'hooks/useFileViewOrDownload'

import { ProjectDetails } from '@/types/rfis/rfi'

type ProjectFileProps = Pick<ProjectDetails, 'fileKey' | 'fileName' | 'fileSize' | 'projectName'>

export const ProjectFile = ({ fileKey, fileName, fileSize, projectName }: ProjectFileProps) => {
  const [isFetchingFile, viewOrDownloadFile] = useFileViewOrDownload(
    'The RFI file is currently unavailable. Please try again later.',
  )
  const size = fileSize ? Number(fileSize / (1000 * 1000)).toFixed(2) + 'MB' : ''
  if (fileKey === '' || fileKey === null || fileKey === undefined) return <></>
  if (fileName === '' || fileName === null || fileName === undefined) return <></>
  return (
    <div className="p-6 pt-2">
      <div className="flex gap-4 justify-between items-center">
        <WppTypography type="xl-heading">Uploaded Files</WppTypography>

        <WppTooltip text="Download the uploaded RFI file">
          <WppActionButton
            disabled={isFetchingFile}
            variant="primary"
            onClick={() =>
              viewOrDownloadFile({ fileObjects: [{ key: fileKey, name: `${projectName}.pdf` }], downloadFile: true })
            }
          >
            {isFetchingFile ? <WppSpinner size="s" /> : <WppIconDownload color="" slot="icon-start" />}
          </WppActionButton>
        </WppTooltip>
      </div>

      <div
        style={{ pointerEvents: isFetchingFile ? 'none' : 'auto' }}
        className="flex gap-2 bg-[#F4F5F7] rounded-6 p-3 cursor-pointer"
        // @ts-ignore
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          viewOrDownloadFile({ fileObjects: [{ key: fileKey, name: `${projectName}.pdf` }], downloadFile: true })
        }}
      >
        <WppIconFile />
        <WppTypography type="s-body" className="underline text-[#343A3F]">
          {fileName}
        </WppTypography>
        {size.length > 0 && (
          <WppTypography type="s-body" className="text-[#697077]">
            ({size})
          </WppTypography>
        )}
      </div>
    </div>
  )
}
