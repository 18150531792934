import { TabsChangeEventDetail } from '@platform-ui-kit/components-library'
import {
  WppBreadcrumb,
  WppButton,
  WppIconChevron,
  WppIconEdit,
  WppSpinner,
  WppTab,
  WppTabs,
  WppTypography,
} from '@wppopen/components-library-react'
import { useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'

import { useGetAgency } from 'api/queries/agencies/useGetAgency'
//import { LoaderProgressWithDescription, ProgressApiRes } from 'components/LoaderProgressWithDescription'
import { queryClient } from 'app/Root'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { AgencyDetails } from 'pages/agency-dashboard/AgencyDetails'
import { EditAgency } from 'pages/agency-dashboard/EditAgency'

import style from './agencyEditDetail.module.scss'
import { AgencyEditKnowledgeBase } from './AgencyEditKnowledgeBase'

export const AgencyEditDetail = () => {
  const [isEditmode, setIsEditmode] = useState(false)
  //const [taskStatus, setTaskStatus] = useState<ProgressApiRes | null>(null)
  const [currentTab, setCurrentTab] = useState('details')
  const { agencyId } = useParams()
  const navigate = useNavigate()
  const { state: locationState } = useLocation()
  const { data: agency, isLoading } = useGetAgency({ params: { id: agencyId! } })

  const handleTabChange = (event: CustomEvent<TabsChangeEventDetail>) => {
    setCurrentTab(event.detail.value)
  }

  const handleEditButtonClick = () => {
    setCurrentTab('details')
    setIsEditmode(prev => !prev)
  }

  const view = locationState?.toggleView
  const dashPath = `/rfi-helper-tool/settings${view ? '?view=' + view : ''}`

  if (isLoading) {
    return (
      <div className="h-[10dvh] flex justify-center items-center">
        <WppSpinner size="m" />
      </div>
    )
  }
  if ((!isLoading && !agencyId) || !agency)
    return <div> Sorry that id isn't recognised, so we're unable to find the agency.</div>

  return (
    <div>
      <div className="flex mb-4">
        <WppIconChevron direction="left" />
        <WppBreadcrumb
          onWppChange={event => navigate(event.detail.path)}
          items={[
            { label: 'Project Dashboard', path: '/' },
            {
              label: 'Settings',
              path: dashPath,
            },
            { label: 'Agency Detail', path: `/rfi-helpder-tool/settings/settings/agency-dashboard/${agencyId}` },
          ]}
        />
      </div>

      {!isEditmode && (
        <div className="flex justify-between items-center mb-4 h-10">
          <WppTypography type="xl-heading">{agency.name}</WppTypography>
          {currentTab === 'details' && (
            <div key="edit-btn">
              <WppButton key="edit-btn" variant="primary" className={style.editButton} onClick={handleEditButtonClick}>
                <div className="flex items-center gap-2">
                  <WppIconEdit color="white" size="s" />
                  <WppTypography type="s-strong" className="text-white">
                    Edit
                  </WppTypography>
                </div>
              </WppButton>
            </div>
          )}
        </div>
      )}
      {isEditmode && (
        <>
          <EditAgency
            agency={agency}
            handleSave={() => {
              setIsEditmode(false)
              queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.AGENCIES] })
            }}
            handleCancel={() => setIsEditmode(false)}
          />
        </>
      )}

      {!isEditmode && (
        <WppTabs value={currentTab} onWppChange={handleTabChange} size="s">
          <WppTab className="w-24" value="details">
            Details
          </WppTab>
          <WppTab className="w-40" value="knowledgeBase">
            Knowledge Base
          </WppTab>
        </WppTabs>
      )}

      {currentTab === 'details' && (
        <AgencyDetails
          agency={agency}
          onKnowledgeBaseUpdate={() => {
            setCurrentTab('knowledgeBase')
          }}
          isEditmode={isEditmode}
        />
      )}

      {currentTab === 'knowledgeBase' && <AgencyEditKnowledgeBase agency={agency} />}

      {/* {taskStatus ? <LoaderProgressWithDescription fullScreen taskStatus={taskStatus} /> : null} */}
    </div>
  )
}
