export enum MessageType {
  QUESTION = 'question',
  ANSWER = 'answer',
}

export interface NewCommentParams {
  rfiQuestionId: string
  commentText: string
  isQuestionComment: boolean
}

export enum ProjectActionType {
  ADD_RFI_CATEGORY_QUESTION = 'ADD_RFI_CATEGORY_QUESTION',
  ASK_RFI_QUESTION = 'ASK_RFI_QUESTION',
  EDIT_RFI_QUESTION = 'EDIT_RFI_QUESTION',
  ADD_COMMENT = 'ADD_COMMENT',
  EDIT_COMMENT = 'EDIT_COMMENT',
  DELETE_COMMENT = 'DELETE_COMMENT',
  VIEW_COMMENT = 'VIEW_COMMENT',
  COPY_TO_CLIPBOARD = 'COPY_TO_CLIPBOARD',
  GENERATE_ANSWERS = 'GENERATE_ANSWERS',
  EDIT_AI_DRAFT_RESPONSE = 'EDIT_AI_DRAFT_RESPONSE',
}
