// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._H4c2{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.qu9XX{position:absolute;width:100%;height:100%;top:0;left:0;opacity:0}`, "",{"version":3,"sources":["webpack://./src/components/Grid/CellWithTooltip.module.scss"],"names":[],"mappings":"AAAA,OACE,eAAA,CACA,sBAAA,CACA,kBAAA,CAGF,OACE,iBAAA,CACA,UAAA,CACA,WAAA,CACA,KAAA,CACA,MAAA,CACA,SAAA","sourcesContent":[".truncate {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n\n.tooltip {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  opacity: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"truncate": `_H4c2`,
	"tooltip": `qu9XX`
};
export default ___CSS_LOADER_EXPORT___;
