import { rfiHelperApi } from 'api'

export interface AppNotification {
  title: string
  body: string
  recipients: string[]
}

export interface AppNotificationResponse {
  success: boolean
}

export const createAppNotification = (props: AppNotification) =>
  rfiHelperApi.post<AppNotificationResponse>('/system/notify-users', {
    ...props,
  })
