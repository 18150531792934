import { QueryClient } from '@tanstack/react-query'
import { EditorState, Modifier } from 'draft-js'

import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { RfiComment, RfiQuestion } from 'types/rfis/rfi'

import { MessageType, ProjectActionType } from './types'
import { Role } from '@/types/users/userList'

export const mergeQuestions = (rfiQuestion: RfiQuestion | null): RfiQuestion[] =>
  (rfiQuestion && [rfiQuestion, ...(rfiQuestion?.childQuestions || [])]) || []

interface MergedQuestion extends RfiQuestion {
  commentState?: {
    showComments: boolean
    showInput: boolean
  }
}

export const updateMergedConversation = (questions: MergedQuestion[]) =>
  questions
    .map(question => {
      const questionMessage = {
        id: `${question.id}-${MessageType.QUESTION}`,
        type: MessageType.QUESTION,
        content: question.questionText,
        timestamp: new Date().toISOString(),
        rfiQuestionId: question.id,
        parentId: question.parentId,
        comments: transformComments(question.questionComments),
        commentState: {
          showComments: question.commentState?.showComments ?? false,
          showInput: question.commentState?.showInput ?? false,
        },
      }
      const answerMessage = {
        id: `${question.id}-${MessageType.ANSWER}`,
        type: MessageType.ANSWER,
        content: question.proposedAnswer,
        timestamp: new Date().toISOString(),
        rfiQuestionId: question.id,
        parentId: question.parentId,
        comments: transformComments(question.answerComments),
        commentState: {
          showComments: question.commentState?.showComments ?? false,
          showInput: question.commentState?.showInput ?? false,
        },
      }
      return [questionMessage, answerMessage]
    })
    .flat()

// https://github.com/jpuri/draftjs-utils/blob/master/js/block.js
export const getResetEditorState = (editorState: EditorState) => {
  const blocks = editorState.getCurrentContent().getBlockMap().toList()
  const updatedSelection = editorState.getSelection().merge({
    anchorKey: blocks.first().get('key'),
    anchorOffset: 0,
    focusKey: blocks.last().get('key'),
    focusOffset: blocks.last().getLength(),
  })
  const newContentState = Modifier.removeRange(editorState.getCurrentContent(), updatedSelection, 'forward')
  const newState = EditorState.push(editorState, newContentState, 'remove-range')
  return newState
}

const {
  ASK_RFI_QUESTION,
  ADD_COMMENT,
  EDIT_COMMENT,
  DELETE_COMMENT,
  VIEW_COMMENT,
  EDIT_RFI_QUESTION,
  COPY_TO_CLIPBOARD,
  GENERATE_ANSWERS,
  EDIT_AI_DRAFT_RESPONSE,
} = ProjectActionType

export const rolesAndPermissions: Record<keyof typeof Role, ProjectActionType[]> = {
  Owner: Object.keys(ProjectActionType) as ProjectActionType[],
  Viewer: [VIEW_COMMENT, COPY_TO_CLIPBOARD],
  Commenter: [VIEW_COMMENT, ADD_COMMENT, COPY_TO_CLIPBOARD, EDIT_COMMENT, DELETE_COMMENT],
  Contributor: [
    VIEW_COMMENT,
    COPY_TO_CLIPBOARD,
    ADD_COMMENT,
    EDIT_COMMENT,
    DELETE_COMMENT,
    ASK_RFI_QUESTION,
    EDIT_RFI_QUESTION,
    GENERATE_ANSWERS,
    EDIT_AI_DRAFT_RESPONSE,
  ],
}

export const hasRequiredPermission = (role: keyof typeof Role, actions?: ProjectActionType[]) => {
  return role === Role.Owner || actions?.every(action => rolesAndPermissions[role]?.includes(action))
}

export const isCommentOwner = (createByEmail: string, currentUserEmail: string) => {
  return createByEmail === currentUserEmail
}

export const transformComments = (comments: RfiComment[]) => {
  return comments.map(comment => ({ ...comment, isEditMode: false }))
}

export const updateQuestionsCache = ({
  rfiId,
  rfiQuestionId,
  proposedAnswer,
  queryClient,
}: {
  rfiId: string
  rfiQuestionId: string
  proposedAnswer: string
  queryClient: QueryClient
}) => {
  // queryClient.cancelQueries({ queryKey: [ApiQueryKeys.RFI_QUESTIONS] })

  const prevQuestions = queryClient.getQueriesData({ queryKey: [ApiQueryKeys.RFI_QUESTIONS, { rfiId: rfiId }] })

  if (prevQuestions.length === 1) {
    const { data: questions } = prevQuestions[0][1] as { data: RfiQuestion[] }
    const updatedQuestions = questions.map(question => {
      if (question.id === rfiQuestionId) {
        return {
          ...question,
          proposedAnswer,
        }
      }
      return question
    })
    queryClient.setQueriesData({ queryKey: [ApiQueryKeys.RFI_QUESTIONS] }, (old: any) => {
      return {
        ...old,
        data: updatedQuestions,
      }
    })
  }
}
