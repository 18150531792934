import { WppButton, WppIconEdit, WppIconTrash, WppModal, WppTypography } from '@wppopen/components-library-react'
import clsx from 'clsx'
import { useEffect, useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { useFileViewOrDownload } from 'hooks/useFileViewOrDownload'
import { UseCase } from 'types/use-cases/useCase'

import { UseCaseDeleteModal } from './UseCaseDeleteModal'
import { UseCasesDetails } from './UseCasesDetails'
import style from './useCasesDetailsEditVeiw.module.scss'
import { UseCasesEditAdd } from './useCasesEditAdd/UseCasesEditAdd'

export const UseCaseDetailsEditView = ({ useCase }: { useCase: UseCase }) => {
  const { fileKey, fileName } = useCase
  const [isFetchingFile, viewOrDownloadFile] = useFileViewOrDownload()
  const [isEditmode, setIsEditmode] = useState(false)

  const navigate = useNavigate()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [disableDeleteButtton, setDisableDeleteButton] = useState(false)
  const handleEditButtonClick = () => setIsEditmode(prev => !prev)

  const handleSave = () => setIsEditmode(false)
  const handleCancel = () => setIsEditmode(false)
  const handleEditing = useCallback((isEditing: boolean) => setDisableDeleteButton(isEditing), [setDisableDeleteButton])
  const [showDeleteButton, setShowDeleteButton] = useState(false)

  useEffect(() => {
    setShowDeleteButton(isEditmode)
  }, [isEditmode, setShowDeleteButton])

  return (
    <div data-is-edit={isEditmode} className={style.UseCaseDetailsEditView}>
      <div className={clsx('flex flex-col gap-4', style.tab)}>
        <div className="flex justify-between items-center mb-4">
          <WppTypography type="xl-heading">{useCase.title}</WppTypography>
          {showDeleteButton ? (
            <WppButton
              disabled={disableDeleteButtton}
              className="ml-auto"
              variant="destructive-secondary"
              onClick={() => setIsDeleteModalOpen(true)}
            >
              <div className="flex items-center gap-2">
                <WppIconTrash
                  color={!disableDeleteButtton ? 'var(--button-destructive-secondary-text-color)' : undefined}
                />
                <span>Delete Use Case</span>
              </div>
            </WppButton>
          ) : (
            <div className="flex justify-end gap-4">
              {fileKey && fileName && (
                <WppButton
                  disabled={isFetchingFile}
                  className={style.editButton}
                  variant="secondary"
                  // @ts-ignore
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    viewOrDownloadFile({ fileObjects: [{ key: fileKey, name: fileName }], downloadFile: true })
                  }}
                >
                  <div className="flex items-center gap-2">
                    <WppTypography type="s-strong" className="text-primary">
                      Download
                    </WppTypography>
                  </div>
                </WppButton>
              )}
              <WppButton className={style.editButton} variant="primary" onClick={handleEditButtonClick}>
                <div className="flex items-center gap-2">
                  <WppIconEdit color="white" size="s" />
                  <WppTypography type="s-strong" className="text-white">
                    Edit
                  </WppTypography>
                </div>
              </WppButton>
            </div>
          )}
        </div>

        {isEditmode ? (
          <UseCasesEditAdd
            useCase={useCase}
            handleCancel={handleCancel}
            handleSave={handleSave}
            handleEditing={handleEditing}
          />
        ) : (
          <UseCasesDetails useCaseId={useCase?.id} />
        )}
      </div>

      <WppModal open={isDeleteModalOpen} onWppModalClose={() => setIsDeleteModalOpen(false)} size="s">
        <UseCaseDeleteModal
          useCaseId={useCase?.id ?? ''}
          name={useCase?.title}
          handleModalClose={() => {
            setIsDeleteModalOpen(false)
          }}
          handleDeleteSuccess={() => {
            setIsDeleteModalOpen(false)
            navigate('/rfi-helper-tool/settings?view=usecases')
          }}
        />
      </WppModal>
    </div>
  )
}
