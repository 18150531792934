import {
  WppAvatar,
  WppTypography,
  WppDivider,
  WppTooltip,
  WppCard,
  WppIconDownload,
} from '@wppopen/components-library-react'
import { Fragment, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { LinkProps } from 'react-router-dom'

import { ProjectPreviewItem } from 'pages/home/ProjectPreviewItem'
import { UseCase } from 'types/use-cases/useCase'

import style from './fileCmp.module.scss'

export default function FileCmp(props: { useCase: UseCase; onClick: (useCase: UseCase) => void }) {
  const { title, markets, pitchTypes, client, createdBy, subCategory } = props.useCase

  const listContainerRef = useRef<HTMLDivElement>(null)
  const titleRef = useRef<HTMLDivElement | null>(null)
  const containerRef = useRef<LinkProps | null>(null)
  const [truncate, setTruncate] = useState(false)
  const [containerWidth, setContainerWidth] = useState(0)
  const rightSidePadding = 40

  const handleTruncate = useCallback(() => {
    setContainerWidth((containerRef.current as HTMLElement | null)?.clientWidth || 0)
    // get the width of the containerRef.current
    const containerWidth = (containerRef.current as HTMLElement | null)?.clientWidth || 0
    /*
     * Create an inline element to hold the text
     * and set the font style to match the span
     * so that the width of the span will match the width of the span
     * and we can get the width of the text
     * to determine if it needs to be truncated
     * NB: due to nested Web Components and shadow DOM issues
     */
    const span = document.createElement('span')
    // Set the font style of the span
    span.style.fontSize = '16px'
    span.style.opacity = '0'
    // Append the text to the span
    span.textContent = `${title}`
    // Append the span to the document body (it will be hidden)
    document.body.appendChild(span)
    // Get the width of the span
    const width = span.offsetWidth
    // Remove the span
    document.body.removeChild(span)

    if (width > containerWidth - rightSidePadding * 2) {
      setTruncate(true)
    }
  }, [title])

  useEffect(() => {
    if (titleRef?.current && containerRef?.current && markets) {
      setTimeout(() => {
        handleTruncate()
      }, 150)
      window.addEventListener('resize', handleTruncate)
    }
    return () => {
      window.removeEventListener('resize', handleTruncate)
    }
  }, [containerWidth, handleTruncate, markets])

  const Component = truncate
    ? ({ children }: { children: ReactNode }) => <WppTooltip text={title}>{children}</WppTooltip>
    : Fragment

  return (
    <>
      <WppCard interactive className="lg:w-[30%] h-[255px]" onClick={() => props.onClick(props.useCase)}>
        <div ref={containerRef as React.RefObject<HTMLDivElement>} className="flex flex-row justify-between relative">
          <div ref={titleRef}>
            <Component>
              <WppTypography
                type="xl-heading"
                className={style.headingTruncation}
                style={{
                  width: `${containerWidth}px`,
                }}
              >
                {title}
              </WppTypography>
            </Component>
          </div>
        </div>
        <div className="mt-auto" ref={listContainerRef}>
          {[
            { name: 'Market:', value: markets },
            { name: 'Client:', value: client },
            { name: 'Pitch Type:', value: pitchTypes.join(', ') },
          ].map(item => (
            <ProjectPreviewItem
              labelText={item.name}
              value={item.value}
              containerWidth={listContainerRef?.current?.offsetWidth || 400}
            />
          ))}
          <ProjectPreviewItem
            labelText="Created By:"
            containerWidth={listContainerRef?.current?.offsetWidth || 400}
            value={createdBy}
          >
            <WppAvatar size="xs" className="m-h-6 m-w-6 flex-shrink-0 flex-grow-0 flex-auto" name={createdBy ?? ''} />
          </ProjectPreviewItem>
        </div>
        <div className="flex flex-col gap-2 justify-center mt-1">
          <WppDivider />
          <div className="flex justify-between">
            {props.useCase.fileUrl && (
              <WppIconDownload
                className="hover:cursor-pointer flex items-center"
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  const link = document.createElement('a')
                  link.href = props.useCase.fileUrl
                  link.target = '_blank' // Open in a new tab

                  link.download = props.useCase.fileUrl?.split('/').pop() ?? ''

                  document.body.appendChild(link)

                  link.click()

                  document.body.removeChild(link)
                }}
              />
            )}
            <span className="text-[#058521] bg-[#63F783] px-2 py-1 rounded-[4px] text-sm">{subCategory}</span>
          </div>
        </div>
      </WppCard>
    </>
  )
}
