import { AxiosError } from 'axios'

import { createApi } from 'api/common/createApi'
import config, { API_TYPES } from 'constants/app-config'

export const masterDataApi = createApi({
  baseURL: config[API_TYPES.MASTER_DATA_API],
})

export const rfiHelperApi = createApi({
  baseURL: config[API_TYPES.RFI_HELPER_API],
})

export const rfiHelperFormApi = createApi({
  baseURL: config[API_TYPES.RFI_HELPER_API],
  headers: {
    'Content-Type': 'multipart/form-data',
  },
})

export const rfiHelperDeleteApi = createApi({
  baseURL: config[API_TYPES.RFI_HELPER_API],
  headers: {
    'Content-Type': 'multipart/form-data',
  },
})

export const userDataApi = createApi({
  baseURL: config[API_TYPES.USERS_API],
})

export const facadeApi = createApi({
  baseURL: '/api',
})

export const gcpApi = createApi({
  baseURL: '/',
})

export const API_ERROR_CODE_TO_DISPLAY_BE_MESSAGE = 560

interface Error {
  detail: string
}
export const getBEErrorMessage = (error: AxiosError<unknown, any>): string | undefined => {
  const { status, response } = error
  if (status !== API_ERROR_CODE_TO_DISPLAY_BE_MESSAGE) {
    return undefined
  }
  const { detail } = response?.data as Error
  return detail ?? undefined
}
