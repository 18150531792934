import Editor from '@draft-js-plugins/editor'
import createMentionPlugin, { MentionData, defaultSuggestionsFilter } from '@draft-js-plugins/mention'
import { EntryComponentProps } from '@draft-js-plugins/mention/lib/MentionSuggestions/Entry/Entry'
import { WppActionButton, WppAvatar, WppIconClose, WppIconDone, WppSpinner } from '@wppopen/components-library-react'
import { AnalyticsActionType } from '@wppopen/core'
import { ContentState, EditorState } from 'draft-js'
import { ReactElement, useCallback, useMemo, useState } from 'react'

import '@draft-js-plugins/mention/lib/plugin.css'

import styles from './CommentInput.module.scss'
import { ANALYTICS_EVENTS, trackAnalytics } from '../../../utils/analytics'
import InputActions from '../input-actions/InputActions'
import { getResetEditorState } from '../utils'

export interface CommentProps {
  handleSubmit: (commentText: string, action: 'add' | 'edit', mentions?: MentionData[]) => void
  isReplyLoading: boolean
  initialComment?: string
  projectMembers: MentionData[]
  projectId: string
  isEdit?: boolean
  handleCloseEdit?: () => void
}

export const Entry = (props: EntryComponentProps): ReactElement => {
  const { selectMention, mention, theme, searchValue, isFocused, ...parentProps } = props

  return (
    <div
      {...parentProps}
      className="flex gap-2 p-2 rounded-lg"
      onClick={() => selectMention(mention)}
      data-testid="Entry"
    >
      <div className="flex gap-2 w-[400px] hover:bg-[#E0EBFF] px-3 py-2 rounded-lg">
        <div className="flex justify-center items-center">
          <WppAvatar name={mention.name} src={mention.avatar} className="w-8 h-8 rounded-full object-contain" />
        </div>
        <div className="flex flex-col gap-[0.4rem]">
          <div className="font-medium text-dark">{mention.name}</div>
          <div className="text-grey">{mention.email}</div>
        </div>
      </div>
    </div>
  )
}

export const CommentInput = ({
  initialComment,
  handleSubmit,
  handleCloseEdit,
  isReplyLoading,
  projectId,
  projectMembers,
  isEdit = false,
}: CommentProps): ReactElement => {
  const [mentions, setMentions] = useState<MentionData[]>([])
  const [editorState, setEditorState] = useState(() =>
    initialComment
      ? EditorState.createWithContent(ContentState.createFromText(initialComment))
      : EditorState.createEmpty(),
  )

  const [open, setOpen] = useState(false)
  const [suggestions, setSuggestions] = useState<MentionData[]>(projectMembers)

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      mentionPrefix: '@',
      entityMutability: 'IMMUTABLE',
    })
    const { MentionSuggestions } = mentionPlugin
    const plugins = [mentionPlugin]
    return { plugins, MentionSuggestions }
  }, [])

  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open)
  }, [])

  const onSearchChange = useCallback(({ value }: { value: string }) => {
    setSuggestions(defaultSuggestionsFilter(value, projectMembers))
    // eslint-disable-next-line
  }, [])

  const onSubmit = () => {
    handleSubmit(content, 'add', mentions)

    const newEditorState = getResetEditorState(editorState)
    setEditorState(newEditorState)
    trackAnalytics(
      {
        type: AnalyticsActionType.action,
        payload: ANALYTICS_EVENTS.POST_COMMENT,
      },
      {
        projectId,
      },
    )
  }

  const content = editorState.getCurrentContent().getPlainText()
  return (
    <>
      <div className={styles.editor} data-testid="CommentInput">
        <Editor
          editorState={editorState}
          onChange={setEditorState}
          plugins={plugins}
          readOnly={isReplyLoading}
          placeholder="Add a comment..."
        />

        <MentionSuggestions
          open={open}
          onOpenChange={onOpenChange}
          suggestions={suggestions}
          onSearchChange={onSearchChange}
          entryComponent={Entry}
          onAddMention={mention => {
            setMentions([...mentions, mention])
          }}
        />
        {!isEdit && (
          <InputActions
            onSubmit={onSubmit}
            question={content}
            answerIsLoading={isReplyLoading}
            testId="CommentInputActions"
          />
        )}
      </div>
      {isEdit && (
        <div className="flex justify-end gap-2">
          <WppActionButton className="cursor-pointer" onClick={handleCloseEdit} disabled={isReplyLoading}>
            <WppIconClose />
          </WppActionButton>
          <WppActionButton
            className="cursor-pointer"
            data-testid="editComment"
            onClick={() => handleSubmit(content, 'edit')}
            disabled={isReplyLoading}
          >
            {isReplyLoading ? <WppSpinner /> : <WppIconDone />}
          </WppActionButton>
        </div>
      )}
    </>
  )
}
