// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IZDAe{width:100%}.IZDAe::part(item){width:317px}.uuqEr{width:100%;text-indent:1rem !important}.uuqEr::part(item){width:317px}`, "",{"version":3,"sources":["webpack://./src/pages/agency-edit-detail/AgencyEditKnowledgeBase.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CAEA,mBACE,WAAA,CAIJ,OACE,UAAA,CACA,2BAAA,CAEA,mBACE,WAAA","sourcesContent":[".sideMenuItem {\n  width: 100%;\n  \n  &::part(item) {\n    width: 317px;\n  }\n}\n\n.sideMenuIndentedItem {\n  width: 100%;\n  text-indent: 1rem !important;\n  \n  &::part(item) {\n    width: 317px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sideMenuItem": `IZDAe`,
	"sideMenuIndentedItem": `uuqEr`
};
export default ___CSS_LOADER_EXPORT___;
