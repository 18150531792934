import { rfiHelperFormApi } from 'api'
import { AgencyFileAddedResponse } from 'types/agencies/agencies'

export interface AddAgencyFileParams {
  params: {
    file_key: string
  }
  agencyId: string
  formData?: FormData
}

export const addAgencyFile = (data: AddAgencyFileParams) => {
  const entries = Object.entries(data.params)
    .flatMap(([key, value]) =>
      Array.isArray(value)
        ? value.map(v => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`) // Handle array values
        : `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
    )
    .join('&')

  if (data?.formData) {
    return rfiHelperFormApi.put<AgencyFileAddedResponse>(`/agency-files/${data.agencyId}?${entries}`, data.formData)
  }

  return rfiHelperFormApi.put<AgencyFileAddedResponse>(`/agency-files/${data.agencyId}?${entries}`)
}
