import { rfiHelperApi } from 'api'

export const mergeAgencyCategories = ({
  fileId,
  categoryIds,
  deleteCategoryIds,
  contentStrategy,
}: {
  fileId: string
  categoryIds: string[]
  deleteCategoryIds: string[]
  contentStrategy?: string
}) =>
  rfiHelperApi.put(
    `/instructions-categories/merge/confirm?agency_file_id=${encodeURIComponent(fileId)}&merge_strategy=${encodeURIComponent(contentStrategy ?? 'merge')}`,
    {
      category_ids: categoryIds,
      delete_category_ids: deleteCategoryIds,
    },
  )
