import { rfiHelperApi } from 'api'

import { AgencyCategory } from '@/types/agency-categories/agency-categories'

export const createAgencyCategory = ({
  agencyId,
  name,
  instruction,
  parentCategoryId,
}: {
  agencyId: string
  name: string
  instruction: string | null
  parentCategoryId: string | null
}) => rfiHelperApi.post<AgencyCategory>('/instructions-categories', { agencyId, name, instruction, parentCategoryId })
