import { QueryClient } from '@tanstack/react-query'
import { ToastState } from '@wppopen/components-library'

import { fetchTaskStatuses } from 'api/fetchers/task-status/taskStatus'
import { ProgressApiRes } from 'components/LoaderProgressWithDescription'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

export interface TaskStatusResponse extends ProgressApiRes {
  handleLocally?: boolean
  successMessage?: string
  errorMessage?: string
  queryKeysToInvalidate?: string[]
  entityType: EntityType
  entityName: string
}

//fast=500ms, normal=2000ms, slow=5000ms
type PollSpeed = 'slow' | 'normal' | 'fast'

type EntityType = 'useCase' | 'project' | 'agency' | 'user'

interface Task {
  id: string
  pending: boolean
  entityType: EntityType
  entityName?: string
  pollSpeed?: PollSpeed
  handleLocally?: boolean // if true, the task will be handled locally and statusCallback  will not be called
  successMessage?: string
  errorMessage?: string
  queryKeysToInvalidate?: string[]
  callback?: (resp: TaskStatusResponse) => void
  timestamp?: number
}

class TaskQueuer {
  private static instance: TaskQueuer
  private TASKS_QUEUE: Task[] = []
  private TIMER_IDS: Record<PollSpeed, number | null> = { slow: null, normal: null, fast: null }
  private POLL_INTERVALS: Record<PollSpeed, number> = { slow: 5000, normal: 2000, fast: 500 }
  private queryClient = new QueryClient()
  private toastCallback?: (config: ToastState) => void
  private statusCallback?: (resp: TaskStatusResponse) => void
  private constructor() {
    this.poll = this.poll.bind(this)
  }

  public set onShowToast(callback: (config: ToastState) => void) {
    if (!this.toastCallback) {
      this.toastCallback = callback
    }
  }
  public set onStatusCallBack(callback: (resp: TaskStatusResponse) => void) {
    if (!this.statusCallback) {
      this.statusCallback = callback
    }
  }
  private async poll(pollSpeed: PollSpeed) {
    const tasks = this.TASKS_QUEUE.filter(task => task.pending && task.pollSpeed === pollSpeed)
    const taskIds = tasks.map(task => task.id)
    if (taskIds.length === 0) {
      if (this.TIMER_IDS[pollSpeed]) {
        clearInterval(this.TIMER_IDS[pollSpeed])
        this.TIMER_IDS[pollSpeed] = null
      }
      //if no polling active clear tasks queue
      const noPollingActive = Object.values(this.TIMER_IDS).every(timerId => timerId === null)
      if (noPollingActive) {
        this.TASKS_QUEUE = this.TASKS_QUEUE.filter(task => task.pending)
      }
      return
    }
    const res = await this.queryClient.fetchQuery({
      queryKey: [ApiQueryKeys.TASKS_STATUS, { taskIds }],
      queryFn: ({ signal }: { signal: any }) =>
        fetchTaskStatuses({
          taskIds: taskIds,
        })(signal),
    })

    const reponses = res.data
    tasks.forEach(task => {
      const response = reponses.find(response => response.id === task.id)
      if (response) {
        const taskStatusResponse = {
          ...response,
          handleLocally: task.handleLocally,
          successMessage: task.successMessage,
          errorMessage: task.errorMessage,
          queryKeysToInvalidate: task.queryKeysToInvalidate,
          entityType: task.entityType,
          entityName: task.entityName,
        } as TaskStatusResponse
        task.callback?.(taskStatusResponse)
        if (this.statusCallback && !task.handleLocally) {
          this.statusCallback(taskStatusResponse)
        }
        if (response.completed || response.error) {
          task.pending = false
        }
      }
      //check for long running tasks 2 minutes timeout
      const seconds = task.pollSpeed === 'fast' ? 45 : 120
      if (task.pending && task.timestamp && Date.now() - task.timestamp > 1000 * seconds) {
        task.pending = false
      }
    })
  }
  public static getInstance(): TaskQueuer {
    if (!TaskQueuer.instance) {
      TaskQueuer.instance = new TaskQueuer()
    }
    return TaskQueuer.instance
  }

  public showToast(config: ToastState) {
    if (this.toastCallback) {
      this.toastCallback(config)
    }
  }

  public queueTask({
    id,
    pollSpeed = 'normal',
    callback,
    handleLocally = false,
    successMessage,
    errorMessage,
    queryKeysToInvalidate,
    entityName,
    entityType,
  }: Pick<
    Task,
    | 'id'
    | 'callback'
    | 'handleLocally'
    | 'successMessage'
    | 'errorMessage'
    | 'pollSpeed'
    | 'queryKeysToInvalidate'
    | 'entityType'
    | 'entityName'
  >): string | undefined {
    if (this.TASKS_QUEUE.find(task => task.id === id)) return undefined
    this.TASKS_QUEUE.push({
      id,
      pollSpeed,
      pending: true,
      handleLocally,
      callback,
      successMessage,
      errorMessage,
      queryKeysToInvalidate,
      timestamp: Date.now(),
      entityName,
      entityType,
    })
    Object.entries(this.TIMER_IDS).forEach(([key, value]) => {
      if (!value) {
        this.poll(key as PollSpeed)
        this.TIMER_IDS[key as PollSpeed] = window.setInterval(
          this.poll,
          this.POLL_INTERVALS[key as PollSpeed],
          key as PollSpeed,
        )
      }
    })

    return id
  }
}

export const taskQueuer = TaskQueuer.getInstance()
