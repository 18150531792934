import { FileItemType } from '@wppopen/components-library/dist/types/components'
import {
  WppTextareaInput,
  WppButton,
  WppDivider,
  WppInput,
  WppTypography,
  WppTooltip,
  WppIconInfo,
} from '@wppopen/components-library-react'
import { AxiosError, AxiosResponse } from 'axios'
import { useCallback, useEffect, useRef, useState } from 'react'

import { useAddAgency } from 'api/mutations/agencies/useAddAgency'
import { useAddAgencyFile } from 'api/mutations/agencies/useAddAgencyFile'
import { useFileUploadUrls } from 'api/queries/files/useFileUploadUrls'
import { queryClient } from 'app/Root'
import { WppControlledUpload, FileUploadChangeProps } from 'components/wpp-controlled-upload/WppControlledUpload'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'hooks/useToast'
import { AgencyAddedResponse, AgencyFileAddedResponse } from 'types/agencies/agencies'

import style from './addagency.module.scss'

export interface AddAgencyModalProps {
  handleCancel?: () => void
  handleSave?: (data: {
    id: string
    name: string
    description: string
    fileName?: string | null
    fileStatusTaskId?: string | null
  }) => void
}

export const AddAgencyModal = ({ handleCancel, handleSave }: AddAgencyModalProps) => {
  const [descriptionValue, setDescriptionValue] = useState<string>('')
  const [agencyExists, seAgencyExists] = useState(false)
  const [nameValue, setNameValue] = useState<string>('')
  const [wppFiles, setWppFiles] = useState<FileItemType[]>([])
  const [fileHasError, setFileHasError] = useState(false)
  const textAreaRef = useRef<HTMLWppTextareaInputElement>(null)
  const [isUploading, setIsUploading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const maxLength = 2000
  const canSave = !!nameValue && descriptionValue.length < maxLength && !fileHasError

  const toast = useToast()
  const [{ data: fileUploadUrls }, generateFileUploadUrls] = useFileUploadUrls()

  const { mutateAsync: addAgency } = useAddAgency({
    onError: error => {
      toast.showToast({
        message: error.message,
        type: 'error',
      })
    },
  })

  const { mutateAsync: addAgencyFile } = useAddAgencyFile({
    onError: error => {
      toast.showToast({
        message: error.message,
        type: 'error',
      })
    },
  })

  const onFileUploadChange = useCallback(({ files, hasError }: FileUploadChangeProps) => {
    setWppFiles(files)
    setFileHasError(hasError)
  }, [])

  const handleClearAll = () => {
    setNameValue('')
    setDescriptionValue('')
    setWppFiles([])
    setFileHasError(false)
    seAgencyExists(false)
  }

  const onHandleCancel = () => {
    handleClearAll()
    handleCancel && handleCancel()
  }

  const onHandleSave = useCallback(async () => {
    setIsSaving(true)

    if (!nameValue) {
      return toast.showToast({
        message: 'Please enter a name for the agency',
        type: 'error',
      })
    }

    if (wppFiles.length > 0) {
      setIsUploading(true)
      generateFileUploadUrls({ file_names: wppFiles.map(file => file.name), file_type: 'agency_file' })
    } else {
      await handleSaveAgency()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateFileUploadUrls, nameValue, toast, wppFiles])

  useEffect(() => {
    if (fileUploadUrls && fileUploadUrls.length) {
      uploadFiles()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUploadUrls])

  const uploadFiles = async () => {
    if (fileUploadUrls && fileUploadUrls.length && wppFiles.length) {
      setIsUploading(true)
      for (const fileUrl of fileUploadUrls) {
        try {
          const formDataAPI = new FormData()
          const files = wppFiles.map(file => new Blob([file as File], { type: 'application/pdf' }))
          const file = files[0]
          formDataAPI.append('file', file, fileUrl.name)
          await fetch(fileUrl.signed_url, {
            headers: {
              'Content-Type': 'application/pdf',
              'x-goog-content-length-range': '1,1000000000',
            },
            body: formDataAPI,
            method: 'put',
          })
        } catch (e) {
          console.error('Error uploading file', e)
          toast.showToast({
            message: 'Your file upload was not successful',
            type: 'error',
          })
          setIsUploading(false)
          return
        }
      }

      handleSaveAgency()
    }
  }

  const handleSaveAgency = async () => {
    const request = { params: { name: nameValue, description: descriptionValue } }

    let agencyResponse: AxiosResponse<AgencyAddedResponse> | null = null
    try {
      agencyResponse = await addAgency(request)

      toast.showToast({
        message: "You've successfully added new agency.",
        type: 'success',
      })

      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.AGENCIES] })

      let fileUploadRes: AxiosResponse<AgencyFileAddedResponse> | null = null
      if (fileUploadUrls?.[0]) {
        fileUploadRes = await addAgencyFile({
          params: {
            file_key: fileUploadUrls[0].key,
          },
          agencyId: agencyResponse.data.id,
        })
      }

      handleSave?.({
        id: agencyResponse.data.id,
        name: nameValue,
        description: descriptionValue,
        fileName: wppFiles[0]?.name,
        fileStatusTaskId: fileUploadRes?.data?.id,
      })
      setIsSaving(false)
      handleClearAll()
    } catch (e) {
      const error = e as AxiosError
      if (error.status === 409) {
        toast.showToast({
          message: 'Agency name already exists. Please use a different one.',
          type: 'error',
        })
        seAgencyExists(true)
        setIsSaving(false)
        setIsUploading(false)
        return
      }

      toast.showToast({
        message: 'Something went wrong while adding the agency. Please try again.',
        type: 'error',
      })
      setIsUploading(false)
    }
    queryClient.resetQueries({ queryKey: [ApiQueryKeys.FILE_UPLOAD_URLS] })
  }

  return (
    <div className="-mx-8">
      <WppDivider />

      <div
        className="px-8 flex flex-col gap-6 py-6 overflow-y-auto"
        style={{
          minHeight: 'calc(100vh - 158px)',
          height: 'calc(100vh - 158px)',
        }}
      >
        <div>
          <WppInput
            labelConfig={{
              text: 'Agency Name',
            }}
            name={nameValue}
            placeholder="Add an agency name"
            required
            value={nameValue}
            onWppChange={e => {
              setNameValue(e?.detail?.value || '')
              seAgencyExists(false)
            }}
            messageType={agencyExists ? 'error' : undefined}
            message={agencyExists ? 'Name already exists. Use a different one.' : undefined}
          />
        </div>
        <WppTextareaInput
          ref={textAreaRef}
          labelConfig={{
            text: 'Description',
          }}
          name="description"
          placeholder="Describe agency"
          onWppChange={e => setDescriptionValue(e?.detail?.value || '')}
          value={descriptionValue}
          charactersLimit={maxLength}
          maxMessageLength={maxLength}
          warningThreshold={maxLength - 100}
          content={descriptionValue}
          className={style.textArea}
        />

        <WppDivider />

        <div className="mb-2 flex flex-row items-center gap-1">
          <WppTypography type="s-strong" className={style.fileUploadtype}>
            Knowledge Base Upload
          </WppTypography>
          <WppTypography type="s-body">(Optional)</WppTypography>
          <WppTooltip
            text="Knowledge base will be changed according to the import selection."
            className={style.filesInfoTooltip}
          >
            <WppIconInfo />
          </WppTooltip>
        </div>
        <div className="mb-2 flex flex-row">
          <WppTypography type="s-body">
            Upload a new file to fill out the agency knowledge base content. After uploading the file, you can preview
            what is being imported and make a selection to confirm the import.
          </WppTypography>
        </div>
        <WppControlledUpload
          acceptConfig={{ 'application/pdf': ['.pdf'] }}
          onFileUploadChange={onFileUploadChange}
          format="arrayBuffer"
          value={wppFiles}
        />
      </div>

      <WppDivider />

      <div className="flex justify-end gap-4 px-8 pt-4 pb-6">
        <WppButton onClick={onHandleCancel} variant="secondary" disabled={isSaving}>
          Cancel
        </WppButton>
        <WppButton
          onClick={() => {
            onHandleSave()
          }}
          disabled={!canSave || isSaving || isUploading}
        >
          {isUploading ? 'Uploading...' : isSaving ? 'Saving...' : 'Next'}
        </WppButton>
      </div>
    </div>
  )
}
