import { rfiHelperApi } from 'api'

import { DownloadUrl } from '@/types/files/downloadUrl'

export interface Props {
  file_keys: string[]
}

export const fetchFileDownloadUrls = ({ file_keys }: Props) => {
  const params = new URLSearchParams()
  file_keys.forEach(el => {
    params.append('file_keys', el)
  })

  return rfiHelperApi.get<DownloadUrl[]>(`/gcp/get_download_urls?${params.toString()}`)
}
