import { ListItemChangeEventDetail } from '@wppopen/components-library'
import { WppListItemCustomEvent } from '@wppopen/components-library/dist/types/components'
import {
  WppAvatar,
  WppTypography,
  WppDivider,
  WppAvatarGroup,
  WppListItem,
  WppIconTrash,
  WppTooltip,
  WppCard,
  WppActionButton,
  WppIconMore,
  WppMenuContext,
  WppIconEye,
  // WppTag,
} from '@wppopen/components-library-react'
import { AnalyticsActionType } from '@wppopen/core'
import { useOs } from '@wppopen/react'
import { Fragment, ReactNode, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { LinkProps, useNavigate } from 'react-router-dom'

import { navigation } from 'components/SidebarCmp'
import useProjectContext from 'hooks/useProjectContext'
import { CreatedOrUpdatedBy, RfiAgency, RfiMember } from 'types/rfis/rfi'
import { useGetMarketsByIds, useGetPitchTypesByIds } from 'utils/projectUtils'

import style from './home.module.scss'
import { ProjectPreviewItem } from './ProjectPreviewItem'
import { trackAnalytics, ANALYTICS_EVENTS } from '../../utils/analytics'

export default function ProjectPreviewCard({
  projectName,
  agencies,
  updatedAt,
  createdAt,
  createdBy,
  marketIds,
  projectMembers,
  id,
  rfiSummary,
  clientName,
  handleSelectedProject,
  pitchTypeIds,
  // activeStatus,
}: Readonly<{
  projectName: string
  activeStatus: number
  clientName: string
  pitchTypeId: string
  pitchTypeIds: string[]
  updatedAt: string
  createdAt: string
  createdBy: CreatedOrUpdatedBy
  isCompleted: boolean
  id: string
  agencies: RfiAgency[]
  projectMembers: RfiMember[]
  marketIds: string[]
  rfiSummary: string
  handleSelectedProject: ({ id, name }: { id: string; name: string }) => void
}>) {
  const { osContext } = useOs()
  const navigate = useNavigate()
  const { setState } = useProjectContext()
  const listContainerRef = useRef<HTMLDivElement>(null)
  const titleRef = useRef<HTMLDivElement | null>(null)
  const containerRef = useRef<LinkProps | null>(null)
  const [truncate, setTruncate] = useState(false)
  const [containerWidth, setContainerWidth] = useState(0)
  const rightSidePadding = 40

  const pitches = useGetPitchTypesByIds(pitchTypeIds)

  const markets = useGetMarketsByIds(marketIds, false) as string
  const agenciesString =
    agencies?.reduce((accum, agency, index, arr) => {
      const isLastOrFirstOfOnly1 = index === arr.length - 1 || (index === 0 && arr.length === 1)
      accum += `${agency?.name}${!isLastOrFirstOfOnly1 ? ', ' : ''}` || ''
      return accum
    }, '') || ''

  const handleEditProjectClick = (e: SyntheticEvent | WppListItemCustomEvent<ListItemChangeEventDetail>) => {
    if (
      (e?.target as HTMLElement)?.dataset?.menu ||
      (e?.target as HTMLElement).textContent?.toLowerCase()?.includes('delete') // TODO translation key
    ) {
      return
    }
    e.preventDefault()
    setState(prev => ({
      ...prev,
      projectId: id,
      rfiSummary,
    }))

    trackAnalytics(
      {
        type: AnalyticsActionType.page,
        payload: ANALYTICS_EVENTS.PROJECT_DETAILS_PAGE_VIEW,
      },
      {
        projectId: id,
      },
    )

    navigate(`${navigation.projectDetails}/${id}`)
  }

  const handleTruncate = useCallback(() => {
    setContainerWidth((containerRef.current as HTMLElement | null)?.clientWidth || 0)
    // get the width of the containerRef.current
    const containerWidth = (containerRef.current as HTMLElement | null)?.clientWidth || 0
    /*
     * Create an inline element to hold the text
     * and set the font style to match the span
     * so that the width of the span will match the width of the span
     * and we can get the width of the text
     * to determine if it needs to be truncated
     * NB: due to nested Web Components and shadow DOM issues
     */
    const span = document.createElement('span')
    // Set the font style of the span
    span.style.fontSize = '16px'
    span.style.opacity = '0'
    // Append the text to the span
    span.textContent = `${projectName}`
    // Append the span to the document body (it will be hidden)
    document.body.appendChild(span)
    // Get the width of the span
    const width = span.offsetWidth
    // Remove the span
    document.body.removeChild(span)

    if (width > containerWidth - rightSidePadding * 2) {
      setTruncate(true)
    }
  }, [projectName])

  useEffect(() => {
    if (titleRef?.current && containerRef?.current && markets) {
      setTimeout(() => {
        handleTruncate()
      }, 150)
      window.addEventListener('resize', handleTruncate)
    }
    return () => {
      window.removeEventListener('resize', handleTruncate)
    }
  }, [containerWidth, handleTruncate, markets])

  const Component = truncate
    ? ({ children }: { children: ReactNode }) => <WppTooltip text={projectName}>{children}</WppTooltip>
    : Fragment

  const userIsOwner = useMemo(() => {
    return projectMembers.find(member => member.memberDetail.email === osContext.userDetails.email)?.role === 'Owner'
  }, [osContext.userDetails.email, projectMembers])

  return (
    <>
      <WppCard interactive className="w-full min-w-80 h-full" onClick={handleEditProjectClick}>
        <div ref={containerRef as React.RefObject<HTMLDivElement>} className="flex flex-row justify-between relative">
          <div ref={titleRef}>
            <Component>
              <WppTypography
                type="xl-heading"
                className={style.headingTruncation}
                style={{
                  width: `${containerWidth - rightSidePadding}px`,
                }}
              >
                {projectName}
              </WppTypography>
            </Component>
          </div>
          <div slot="actions">
            <WppMenuContext data-menu>
              <WppActionButton slot="trigger-element" data-menu>
                <WppIconMore direction="horizontal" data-menu />
              </WppActionButton>
              <div>
                <WppListItem onWppChangeListItem={handleEditProjectClick}>
                  <p slot="label">
                    <WppIconEye className="absolute left-4" />
                    <span className="pl-8">View</span>
                  </p>
                </WppListItem>
                {userIsOwner && (
                  <WppListItem
                    onWppChangeListItem={e => {
                      e.stopPropagation() // not working
                      handleSelectedProject({ id, name: projectName })
                    }}
                  >
                    <p slot="label">
                      <WppIconTrash className="absolute left-4" />
                      <span className="pl-8">Delete</span>
                    </p>
                  </WppListItem>
                )}
              </div>
            </WppMenuContext>
          </div>
        </div>
        <div className="mt-auto min-h-48" ref={listContainerRef}>
          <ProjectPreviewItem
            labelText="Last saved"
            value={new Date(updatedAt || createdAt).toLocaleString()}
            containerWidth={listContainerRef?.current?.offsetWidth || 300}
          />
          <ProjectPreviewItem
            labelText="Agencies"
            value={agenciesString}
            containerWidth={listContainerRef?.current?.offsetWidth || 300}
          />
          <ProjectPreviewItem
            labelText="Client"
            value={clientName}
            containerWidth={listContainerRef?.current?.offsetWidth || 300}
          />
          <ProjectPreviewItem
            labelText="Markets"
            value={markets || ''}
            containerWidth={listContainerRef?.current?.offsetWidth || 300}
          />

          <ProjectPreviewItem
            labelText="Pitch Types"
            value={pitches || ''}
            containerWidth={listContainerRef?.current?.offsetWidth || 400}
          />

          <ProjectPreviewItem
            labelText="Created By"
            containerWidth={listContainerRef?.current?.offsetWidth || 400}
            value={createdBy?.name}
          >
            <WppAvatar
              size="xs"
              className="m-h-6 m-w-6 flex-shrink-0 flex-grow-0 flex-auto"
              src={createdBy?.img ?? ''}
              name={createdBy?.name || ''}
            />
          </ProjectPreviewItem>
        </div>
        <WppDivider className="mt-2 mb-2" />
        <div className="flex items-end">
          {projectMembers && (
            <WppAvatarGroup
              className="pt-2"
              maxAvatarsToDisplay={3}
              size="xs"
              withTooltip
              avatars={projectMembers?.map(member => ({
                name: member.memberDetail.name,
                src: member.memberDetail.img ?? '',
              }))}
            />
          )}
          {/*{Boolean(activeStatus) && (*/}
          {/*  <WppTag className="ml-auto" variant="positive" label={activeStatus === 1 ? 'Done' : 'In Progress'} />*/}
          {/*)}*/}
        </div>
      </WppCard>
    </>
  )
}
