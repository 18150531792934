import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

type ApiSortFilterReturn<T, K> = [{ sortingState: T }, (type: K, sortAsc: boolean) => void]

export type SortValues = 'ASC' | 'DESC' | undefined

export type ApiSortTypes<T extends string | number | symbol> = {
  [key in T]?: SortValues
}

export const useApiSortFilters = <TReturn, TKeys>(
  initialSortingState: TReturn,
): ApiSortFilterReturn<TReturn, TKeys> => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [sortingState, setSortingState] = useState<TReturn>(initialSortingState)

  const handleSortFilter = (type: TKeys, sortAscending: boolean) => {
    const newSortAsc = sortAscending
    setSearchParams(prev => {
      prev.set('order', `${type}:${newSortAsc ? 'ASC' : 'DESC'}`)
      prev.set('page', '1')
      return prev
    })
  }
  useEffect(() => {
    const order = searchParams.get('order')
    if (order) {
      const [sortType, sortDirection] = order.split(':')
      const activeSort = { [sortType]: sortDirection } as TReturn
      setSortingState({ ...activeSort })
    }
  }, [searchParams])

  return [{ sortingState }, handleSortFilter]
}
