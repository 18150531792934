import {
  FileItemType,
  FileUploadEventDetail,
  WppFileUploadCustomEvent,
} from '@wppopen/components-library/dist/types/components'
import { WppFileUpload } from '@wppopen/components-library-react'
import { ComponentProps, useCallback } from 'react'

export interface FileUploadChangeProps {
  files: FileItemType[]
  hasError: boolean
}

interface Props extends ComponentProps<typeof WppFileUpload> {
  onFileUploadChange: (params: FileUploadChangeProps) => void
}

export const WppControlledUpload = (props: Props) => {
  const { onFileUploadChange, multiple } = props
  const handleFileUploadChange = useCallback(
    (event: WppFileUploadCustomEvent<FileUploadEventDetail>) => {
      let fileNames: string[] = []
      let files = event.detail.value ?? []
      // Error files are coming duplicated, so we need to filter them out
      let hasError = event.detail.hasError
      if (multiple) {
        const errorFiles = event.detail.errorFiles.filter(file => {
          if (fileNames.includes(file.name)) {
            return false
          }
          fileNames.push(file.name)
          return true
        })
        files.push(...errorFiles)
      } else {
        files = [...event.detail.errorFiles, ...(event.detail.value ?? [])].filter(file => {
          if (fileNames.includes(file.name)) {
            return false
          }
          fileNames.push(file.name)
          return true
        })
        if (files.length > 0) {
          const file = files[files.length - 1]
          hasError = (file.formatError ?? false) || (file.sizeError ?? false)
          files = [file]
        } else {
          hasError = false
        }
      }

      onFileUploadChange({ files, hasError })
    },
    [onFileUploadChange, multiple],
  )

  return <WppFileUpload {...props} onWppChange={handleFileUploadChange} controlled />
}
