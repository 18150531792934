// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jzaB4::part(typography){color:var(--color-font-grey)}.JeOcz::part(textarea):focus{background-color:#fff}.JG_ZE{color:var(--button-destructive-secondary-text-color)}`, "",{"version":3,"sources":["webpack://./src/pages/agency-dashboard/addagency.module.scss"],"names":[],"mappings":"AAAA,yBACE,4BAAA,CAIA,6BACE,qBAAA,CAIJ,OACE,oDAAA","sourcesContent":[".fileUploadtype::part(typography) {\n  color: var(--color-font-grey);\n}\n\n.textArea::part(textarea) {\n  &:focus {\n    background-color: #ffffff;\n  }\n}\n\n.dangerColor {\n  color: var(--button-destructive-secondary-text-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fileUploadtype": `jzaB4`,
	"textArea": `JeOcz`,
	"dangerColor": `JG_ZE`
};
export default ___CSS_LOADER_EXPORT___;
