import { WppSpinner } from '@wppopen/components-library-react'
import { AgGridReact } from 'ag-grid-react'
import { useRef } from 'react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import '@wppopen/components-library/dist/collection/ag-theme-wpp.css'

import { useGetAllAgencies } from 'api/queries/agencies/useGetAgencies'
import { ApiPaginator } from 'components/ApiPaginator'
import HeaderCellApiSort from 'components/Grid/HeaderCellApiSort'
import { SettingsTabs } from 'pages/settings/Settings'

import { columnDefenitions } from './columnsDefenitions'
import { sortType, AgTableProps } from '../useCasesFilterGroup/types'
import { UseCase } from '@/types/use-cases/useCase'

export type TableDataItem = UseCase & { user: { name: string; img: string } }

export const AgGridTable = ({
  items,
  paginator,
  handleEditClick,
  handleDeleteClick,
  handleSortFilter,
  sortingState,
}: AgTableProps) => {
  const { data: agencies } = useGetAllAgencies({
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 10, //10 minutes
  })
  const gridRef = useRef<AgGridReact<TableDataItem>>(null)

  const columnDefs = columnDefenitions(agencies, handleEditClick, handleDeleteClick, sortingState)

  return (
    <div data-testid="ag-grid-table">
      <div className="ag-theme-wpp mb-[32] w-full" key="123">
        <AgGridReact
          ref={gridRef}
          gridId="agency-grid"
          rowData={items}
          columnDefs={columnDefs}
          tooltipShowDelay={1000}
          defaultColDef={{
            cellClass: 'truncate',
            headerComponent: HeaderCellApiSort,
            sortable: false,
          }}
          onRowClicked={({ data, event }) => {
            const target = event?.target as HTMLElement
            if (!target?.dataset?.contextMenu) {
              handleEditClick(data.id)
            }
          }}
          onColumnHeaderClicked={({ api, column }) => {
            const id = column.getId() as sortType
            const sortable = api.getColumn(id)!?.getColDef()?.sortable
            if (!sortable) return
            const sort = api.getColumn(id)!.getSort() ?? 'asc'
            handleSortFilter(id, sort === 'asc')
          }}
          rowDragMultiRow
          animateRows={false}
          rowDragManaged
          suppressPaginationPanel
          loadingOverlayComponent={() => <WppSpinner size="m" />}
          rowClass="cursor-pointer"
          rowClassRules={{
            'with-error': data => (data.data ? !!data.data.meta?.error : false),
          }}
          domLayout="autoHeight"
        />
      </div>
      <ApiPaginator paginator={paginator} viewName={SettingsTabs.USE_CASES} />
    </div>
  )
}
