import { ToastState } from '@wppopen/components-library'
import { useCallback } from 'react'

import { queryClient } from 'app/Root'
import { taskQueuer, TaskStatusResponse } from 'components/TasksNotifications/TaskQueuer'
import { useToast } from 'hooks/useToast'

export const TasksNotifications = () => {
  const toast = useToast()
  const toastCallback = useCallback(
    (config: ToastState) => {
      toast.showToast(config)
    },
    [toast],
  )
  const taskStatusCallback = useCallback(
    (resp: TaskStatusResponse) => {
      const { status, completed, error, successMessage, errorMessage, queryKeysToInvalidate } = resp || {}

      if (status === 'failed') {
        if (errorMessage) {
          toast.showToast({
            message: errorMessage,
            type: 'error',
          })
        }
      }
      if (status?.toLowerCase()?.includes('error') || error) {
        if (errorMessage) {
          toast.showToast({
            message: errorMessage,
            type: 'error',
          })
        }

        return
      }

      if (status === 'completed' || completed) {
        if (queryKeysToInvalidate) {
          queryKeysToInvalidate.forEach(key => {
            queryClient.invalidateQueries({ queryKey: [key] })
          })
        }
        if (successMessage) {
          toast.showToast({
            message: successMessage,
            type: 'success',
          })
        }
      }
    },
    [toast],
  )
  taskQueuer.onShowToast = toastCallback
  taskQueuer.onStatusCallBack = taskStatusCallback

  return <></>
}
